import { Pipe, PipeTransform } from '@angular/core';
import { TopFactoryHittersSearchResult } from '../state/dashboard/dashboard.reducers';

@Pipe({
    name: 'topFactoryHittersResultLabelsChart',
    standalone: true
})

export class TopFactoryHittersResultLabelsChartPipe implements PipeTransform {

    constructor() { }

    transform(result: TopFactoryHittersSearchResult, ...args: any): string[] {
        let barChartLabels = [];
        for (let row of result.rows) {
            barChartLabels.push(row.sendingUnit)
        }
        return barChartLabels
    }
}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { formatDate } from '@angular/common';
import DuplicateReportRequest from 'src/app/model/statistics/DuplicateReportRequest';
import { TECTStateService } from 'src/app/service/tect-state.service';
import { BaseChartDirective } from 'ng2-charts';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  selector: 'app-most-common-error-filter',
  imports: [
    BaseChartDirective,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule
  ],
  templateUrl: './most-common-error-filter.component.html',
  styleUrls: ['./most-common-error-filter.component.scss']
})
export class MostCommonErrorFilterComponent implements OnInit {
  public request: DuplicateReportRequest;

  sendingUnitFieldVisible: boolean;
  productNumberFieldVisible: boolean;
  errorTypeFieldVisible: boolean;
  rStateFieldVisible: boolean;
  manufacturingUnitVisible: boolean;
  missingInTracyRecordTypeVisible: boolean;

  monthCheckboxVisible: boolean;
  inputFieldVisible: boolean;

  errorCodeForm: FormGroup;
  missingInTracyRecordTypeForm: FormGroup;

  @Output() searchClicked: EventEmitter<DuplicateReportRequest> = new EventEmitter<DuplicateReportRequest>();
  @Output() downloadClicked: EventEmitter<DuplicateReportRequest> = new EventEmitter<DuplicateReportRequest>();

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private stateService: TECTStateService,
  ) {
    this.dateAdapter.setLocale('sv-SE');
    this.request = new DuplicateReportRequest();
  }

  ngOnInit() {
    this.setDatePeriod();
  }

  private setDatePeriod() {
    const currentDatePeriod = this.stateService.getDatePeriod();
    this.request.fromDate = currentDatePeriod.dateFrom;
    this.request.toDate = currentDatePeriod.dateTo;
  }

  periodFromDateChange(event) {
    this.request.fromDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
  }
  periodUntilDateChange(event) {
    this.request.toDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
  }

  getReport() {
    this.searchClicked.emit(this.request);
  }

  downloadExcel() {
    this.downloadClicked.emit(this.request);
  }

  printAction() {
    alert('Printing has not been implemented.');
  }
}
import { Component, OnInit } from '@angular/core';
import { ApiDataService } from 'src/app/service/api-data.service';
import InvalidSerialNumberSearchRequest from 'src/app/model/algorithm-assisted/invalid-serial-number/InvalidSerialNumberSearchRequest';
import { TECTStateService } from 'src/app/service/tect-state.service';
import { CommonModule, formatDate } from '@angular/common';
import InvalidSerialNumberSearchResultRow from 'src/app/model/algorithm-assisted/invalid-serial-number/InvalidSerialNumberSearchResultRow';
import { NotificationService } from 'src/app/service/notification.service';
import InvalidSerialNumberCountRequest from 'src/app/model/algorithm-assisted/invalid-serial-number/InvalidSerialNumberCountRequest';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SpinnerLoaderService } from 'src/app/service/spinner-loader.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSortModule } from '@angular/material/sort';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatPaginatorModule,
    MatTableModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule,
    MatSortModule
  ],
  providers: [
    HttpClient,
    SpinnerLoaderService
  ],
  selector: 'app-invalid-serial-number-search',
  templateUrl: './invalid-serial-number-search.component.html',
  styleUrls: ['./invalid-serial-number-search.component.scss']
})
export class InvalidSerialNumberSearchComponent implements OnInit {
  request: InvalidSerialNumberSearchRequest;
  invalidSerialNumberResult: InvalidSerialNumberSearchResultRow[] = [];
  dataSource = new MatTableDataSource<InvalidSerialNumberSearchResultRow>(this.invalidSerialNumberResult);
  dataTableVisibility = false;
  errorCount = 0;
  pageSizeOptions: number[] = [20, 50, 100];
  displayedColumns = [
    'events',
    'serialNumberReferences',
    'sendingUnit',
    'creationDate',
  ];

  constructor(
    private apiDataService: ApiDataService,
    private dateAdapter: DateAdapter<Date>,
    private stateService: TECTStateService,
    private notificationService: NotificationService) {

    this.dateAdapter.setLocale('sv-SE');
    this.request = new InvalidSerialNumberSearchRequest();
  }

  ngOnInit(): void {
    this.setDatePeriod();
  }

  private setDatePeriod() {
    const currentDatePeriod = this.stateService.getDatePeriod();
    this.request.fromDate = currentDatePeriod.dateFrom;
    this.request.toDate = currentDatePeriod.dateTo;
  }

  periodFromDateChange(event) {
    this.request.fromDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
  }
  periodUntilDateChange(event) {
    this.request.toDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
  }

  performSearch() {
    this.loadSearchResultsFromApi(this.request);
    this.loadCountFromApi(this.request);
  }

  private notifyIfEmpty() {
    if (this.invalidSerialNumberResult.length === 0) {
      this.notificationService.showSuccess('No errors matched your search.');
    }
  }

  onRowClicked(currentRow: InvalidSerialNumberSearchResultRow) {
    console.log('We require more vespene gas!');
  }

  loadMoreRows(event?: PageEvent) {
    this.request.pageIndex = event.pageIndex;
    this.request.pageSize = event.pageSize;
    this.loadSearchResultsFromApi(this.request);
    return event;
  }

  private loadSearchResultsFromApi(request: InvalidSerialNumberSearchRequest) {
    this.apiDataService.searchInvalidSerialNumber(request).subscribe((response: InvalidSerialNumberSearchResultRow[]) => {
      this.invalidSerialNumberResult = response;
      this.dataSource.data = this.invalidSerialNumberResult;
      this.dataTableVisibility = true;
      this.notifyIfEmpty();
    }, (error) => {
      this.notificationService.showError('An unknown error occurred');
    });
  }

  private loadCountFromApi(request: InvalidSerialNumberSearchRequest) {
    var countRequest: InvalidSerialNumberCountRequest = {
      fromDate: request.fromDate,
      toDate: request.toDate
    };

    this.apiDataService.countInvalidSerialNumber(countRequest).subscribe(response => {
      this.errorCount = response.count;
    }, error => {
      this.notificationService.showError('An unknown error occurred');
    });
  }
}

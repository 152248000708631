import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as DashboardActions from '../../state/dashboard/dashboard.actions';
import { Observable } from 'rxjs';
import {
    selectErrorInflowResult,
    selectTopErrorsResult,
    selectDashboardSearchRequest,
    selectTopFactoryHittersResult
} from 'src/app/state/dashboard/dashboard.selectors';
import { DashboardSearchRequest } from 'src/app/model/dashboard/dashboardSearchRequest';
import {
    ErrorInflowSearchResult,
    TopErrorsSearchResult,
    TopFactoryHittersSearchResult
} from 'src/app/state/dashboard/dashboard.reducers';
import { TopErrorResultChartPipe } from 'src/app/pipe/top-error-result.pipe';
import { CommonModule } from '@angular/common';
import { DashboardSearchComponent } from './dashboard-search/dashboard-search.component';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { DashboardTopFactoryHittersComponent } from './dashboard-top-factory-hitters/dashboard-top-factory-hitters/dashboard-top-factory-hitters.component';
import { TopFactoryHittersResultLabelsChartPipe } from 'src/app/pipe/top-factory-hitters-result-labels.pipe';
import { TopFactoryHittersBarChartComponent } from './dashboard-top-factory-hitters-bar-chart/top-factory-hitters-bar-chart.component';
import { TopFactoryHittersResultChartPipe } from 'src/app/pipe/top-factory-hitters-result.pipe';
import { DashboardTopErrorsComponent } from './dashboard-top-errors/dashboard-top-errors.component';
import { TopErrorResultLabelsChartPipe } from 'src/app/pipe/top-error-result-labels.pipe';
import { TopErrorsBarChartComponent } from './top-errors-bar-chart/top-errors-bar-chart.component';
import { DashboardErrorInflowComponent } from './dashboard-error-inflow/dashboard-error-inflow.component';
import { ErrorInflowResultLabelsPipe } from 'src/app/pipe/error-inflow-labels.pipe';
import { ErrorInflowBarChartComponent } from './error-inflow-bar-chart/error-inflow-bar-chart.component';
import { ErrorInflowResultChartPipe } from 'src/app/pipe/error-inflow-chart.pipe';
import { ErrorCategoryPipe } from 'src/app/pipe/error-category.pipe';
import { EventCodePipe } from 'src/app/pipe/event-code.pipe';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        DashboardSearchComponent,
        MatTabsModule,
        RouterModule,
        DashboardTopFactoryHittersComponent,
        TopFactoryHittersResultLabelsChartPipe,
        TopFactoryHittersBarChartComponent,
        TopFactoryHittersResultChartPipe,
        DashboardTopErrorsComponent,
        TopErrorResultLabelsChartPipe,
        TopErrorsBarChartComponent,
        TopErrorResultChartPipe,
        DashboardErrorInflowComponent,
        ErrorInflowResultLabelsPipe,
        ErrorInflowBarChartComponent,
        ErrorInflowResultChartPipe
    ],
    providers: [
        ErrorCategoryPipe,
        EventCodePipe
    ],
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    topErrorsResult$: Observable<TopErrorsSearchResult>;
    errorInflowResult$: Observable<ErrorInflowSearchResult>;
    topFactoryHittersResult$: Observable<TopFactoryHittersSearchResult>;
    searchRequest$: Observable<DashboardSearchRequest>;

    constructor(
        private store: Store<AppState>,
        private topErrorResultChartPipe: TopErrorResultChartPipe
    ) { }

    ngOnInit(): void {
        this.topErrorsResult$ = this.store.select(selectTopErrorsResult);
        this.errorInflowResult$ = this.store.select(selectErrorInflowResult);
        this.topFactoryHittersResult$ = this.store.select(selectTopFactoryHittersResult);
        this.searchRequest$ = this.store.select(selectDashboardSearchRequest);
    }

    performSearch(event: DashboardSearchRequest) {
        this.store.dispatch(DashboardActions.setSearchRequests({ request: event }));
        this.store.dispatch(DashboardActions.loadErrorInflowResults({ loadCount: true }));
        this.store.dispatch(DashboardActions.loadTopErrorsResults({ loadCount: true }));
        this.store.dispatch(DashboardActions.loadTopFactoryHittersResults({ loadCount: true }));
    }

    exportTopErrorsToExcel() {
        this.store.dispatch(DashboardActions.exportTopErrorsToExcel());
    }

    exportErrorInflowToExcel() {
        this.store.dispatch(DashboardActions.exportErrorInflowToExcel());
    }

    exportTopFactoryHittersToExcel() {
        this.store.dispatch(DashboardActions.exportTopFactoryHittersToExcel());
    }
}

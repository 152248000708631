<div class="div-panel sticky-top">
    <div class="row">
        <div class="col-md-6">
            <mat-form-field>
                <input *ngIf="canReadAllErrors" matInput [(ngModel)]="sendingUnit" type="text"
                    placeholder="Sending Unit" aria-label="Search" (keyup.enter)="searchErrorSummary()">
                <mat-select *ngIf="!canReadAllErrors" placeholder="Sending Unit" [(value)]=sendingUnit>
                    <mat-option *ngFor="let unit of userUnits" [value]="unit">
                        {{ unit }}
                    </mat-option>
                </mat-select>
                <mat-label>Sending Unit</mat-label>
            </mat-form-field>
            <button mat-flat-button style="margin: 1em" (click)=searchErrorSummary()>
                <i matIcon class="fa fa-search"></i>
            </button>
        </div>
        <div class="col-md-3">
            <mat-form-field class="input-with-datepicker">
                <input readonly="true" (dateChange)="fromDateChange($event)" matInput [matDatepicker]="fromdate"
                    placeholder="Period from" [value]="fromDate" [max]="toDate || currentDate">
                <mat-datepicker-toggle matSuffix [for]="fromdate"></mat-datepicker-toggle>
                <mat-datepicker #fromdate></mat-datepicker>
                <mat-label>Period from</mat-label>
            </mat-form-field>
        </div>

        <div class="col-md-3">
            <mat-form-field class="input-with-datepicker">
                <input readonly="true" (dateChange)="toDateChange($event)" matInput [matDatepicker]="todate"
                    placeholder="Period until" [value]="toDate" [min]="fromDate" [max]="currentDate">
                <mat-datepicker-toggle matSuffix [for]="todate"></mat-datepicker-toggle>
                <mat-datepicker #todate></mat-datepicker>
                <mat-label>Period until</mat-label>
            </mat-form-field>
        </div>
    </div>
</div>
import { Injectable, OnDestroy } from '@angular/core';
import { DatePeriod } from '../model/DatePeriod';
import { format, addYears } from 'date-fns';
import { TECTStateService } from './tect-state.service';
import { ApiDataService } from './api-data.service';
import { LoginService, Identity } from './login.service';
import { LookupService } from './lookup.service';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StartupService implements OnDestroy {
    private loginSubscription: Subscription;

    constructor(
        private stateService: TECTStateService,
        private loginService: LoginService,
        private apiDataService: ApiDataService,
        private lookupService: LookupService) {
        this.registerLoginObserver();
    }

    public setDatePeriodSearch() {
        // Set default date period in the application to the last year
        const datePeriod = new DatePeriod();
        const now = new Date();
        const lastYear = addYears(now, -1);
        datePeriod.dateFrom = format(lastYear, 'yyyy-MM-dd');
        datePeriod.dateTo = format(now, 'yyyy-MM-dd');
        this.stateService.setDatePeriod(datePeriod);
    }

    public registerLoginObserver() {
        // Subscribe to the loggedIn observable
        this.loginSubscription = this.loginService.observeLoggedIn().subscribe((identity: Identity) => {
            this.loadAllLookups();
        });
    }

    private loadAllLookups() {
        this.apiDataService.lookupErrorCategories().subscribe(response => {
            this.lookupService.setErrorCategories(response);
        });

        this.apiDataService.lookupErrorStatus().subscribe(response => {
            this.lookupService.setErrorStatuses(response);
        });

        this.apiDataService.lookupEventCodes().subscribe(response => {
            this.lookupService.setEventCodes(response);
        });

        this.apiDataService.lookupDateGroupingOptions().subscribe(response => {
            this.lookupService.setDateGroupingOptions(response);
        });

        this.apiDataService.lookupPolicies().subscribe(response => {
            this.lookupService.setPolicies(response);
        });
    }

    ngOnDestroy() {
        // Unsubscribe to prevent memory leaks
        if (this.loginSubscription) {
            this.loginSubscription.unsubscribe();
        }
    }
}

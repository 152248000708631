<div class="div-panel">
    <mat-horizontal-stepper linear #stepper (selectionChange)="onStepChange($event)">
        <mat-step label [editable]="true">
            <ng-template matStepLabel>Select Correction Steps</ng-template>
            <div *ngIf="!isAddingStep">
                <div class="correction-steps" *ngIf="correctionSteps$ | async as steps">
                    <app-batch-process-step-display *ngFor="let step of steps" [step]="step"
                        (removeStepEvent)="onRemoveStep($event)">
                    </app-batch-process-step-display>
                </div>
                <button type="button" mat-flat-button mat-button (click)="startAddingStep()">
                    <mat-icon>add</mat-icon> Add another step
                </button>
            </div>
            <app-batch-process-step-form *ngIf="isAddingStep" (newStepDefined)="addNewStep($event)"
                (cancel)="stopAddingStep()" [fieldTypeValues]="fieldTypeValues$ | async">
            </app-batch-process-step-form>
            <div class="stepper-buttons" *ngIf="!isAddingStep">
                <button type="button" color="primary" mat-flat-button matStepperNext>Preview
                    Changes</button>
            </div>
        </mat-step>

        <mat-step label [editable]="false">
            <ng-template matStepLabel>Preview Result</ng-template>
            <app-batch-process-preview [errors]="errors$ | async" [preview]="preview$ | async">
            </app-batch-process-preview>

            <div class="stepper-buttons">
                <button type="button" color="primary" mat-flat-button matStepperPrevious>Previous</button>
                <button type="button" color="primary" mat-flat-button matStepperNext>Perform Batch
                    Correction</button>
            </div>
        </mat-step>

        <mat-step label>
            <ng-template matStepLabel>Complete</ng-template>
            <app-batch-process-completion>
            </app-batch-process-completion>
            <button type="button" color="primary" mat-flat-button matStepperNext (click)="navigateAway()">Done</button>
        </mat-step>
    </mat-horizontal-stepper>
</div>
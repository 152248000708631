import { addDays, format } from 'date-fns';
import { MITInflowResultRow } from 'src/app/model/missing-in-tracy/MITInflowResultRow';
import * as MITActions from './missing-in-tracy.actions';
import { TopMITFactoryHittersResultRow } from 'src/app/model/missing-in-tracy/topMITFactoryHittersResultRow';
import { MITSearchRequest } from 'src/app/model/missing-in-tracy/MITSearchRequest';
import { createReducer, on } from '@ngrx/store';

export interface MITState {
    searchRequest: MITSearchRequest | null;
    MITInflowResult: MITInflowSearchResult | null;
    topMITFactoryHittersResult: TopMITFactoryHittersSearchResult | null;
}

export interface MITInflowSearchResult {
    rows: MITInflowResultRow[] | null;
    count: number;
}

export interface TopMITFactoryHittersSearchResult {
    rows: TopMITFactoryHittersResultRow[] | null;
    count: number;
}

const initialMITState: MITState = {
    searchRequest: {
        fromDate: format(addDays(new Date(), -1), 'yyyy-MM-dd'),
        toDate: format(new Date(), 'yyyy-MM-dd'),
        dateGrouping: 'Day',
        eventType: '',
        sendingUnit: '',
        productNumber: '',
        revisionState: '',
        errorCategory: '',
        pageSize: 100,
    },
    MITInflowResult: null,
    topMITFactoryHittersResult: null
};

export const MITReducer = createReducer(
    initialMITState,
    on(MITActions.setSearchRequests, (state, { request }) => ({
        ...state,
        searchRequest: request,
    })),
    on(MITActions.loadMITInflowResultsSuccess, (state, { rows, count }) => ({
        ...state,
        MITInflowResult: {
            rows,
            count: count ?? state.MITInflowResult?.count,
        }
    })),
    on(MITActions.loadTopMITFactoryHittersResultsSuccess, (state, { rows, count }) => ({
        ...state,
        topMITFactoryHittersResult: {
            rows,
            count: count ?? state.topMITFactoryHittersResult?.count,
        }
    }))
);

import environment from './environment';

// The assumption is that the URL coming in is on the form [dev|test].errorcorrection.hwtraceability.domain
// We transform the URL to [dev|test].hwtraceability.domain

const tracyBaseUrl = `${window.location.protocol}//${window.location.hostname.replace("errorcorrection.", "")}/`;

interface IStaticEnvironment {
    readonly compiled: boolean;
    readonly apiBaseUrl: string;
    readonly tracyFrontend: string,
    readonly tracyCheckCookieUrl: string;
    readonly tracyRenewCookieUrl: string;
    readonly tracyLogoutUrl: string;
}

const staticEnvironment: IStaticEnvironment = {
    compiled: true,
    apiBaseUrl: '/api/v1/',
    tracyFrontend: tracyBaseUrl,
    tracyCheckCookieUrl: tracyBaseUrl + "api/private/v1/auth/web-user/check-cookie",
    tracyRenewCookieUrl: tracyBaseUrl + "api/private/v1/auth/web-user/token-renewal",
    tracyLogoutUrl: tracyBaseUrl + "api/private/v1/auth/web-user/log-out",
    ...environment,
};

export default staticEnvironment;

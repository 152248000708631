import { Component, ErrorHandler, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { IdleMonitorService } from '../service/idle-monitor-service';
import { Identity, LoginService } from '../service/login.service';
import { StartupService } from '../service/startup.service';
import { SpinnerLoaderComponent } from './spinner-loader/spinner-loader.component';
import { CommonModule, DatePipe } from '@angular/common';
import { ApiDataService } from '../service/api-data.service';
import { FileDownloaderService } from '../service/file-downloader.service';
import { LoggingService } from '../service/logging.service';
import { ErrorCategoryPipe } from '../pipe/error-category.pipe';
import { EventCodePipe } from '../pipe/event-code.pipe';
import { ErrorStatusPipe } from '../pipe/error-status.pipe';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTableModule } from '@angular/material/table';
import { ErrorInflowResultChartPipe } from '../pipe/error-inflow-chart.pipe';
import { ErrorInflowResultLabelsPipe } from '../pipe/error-inflow-labels.pipe';
import { MITInflowResultChartPipe } from '../pipe/missing-in-tracy/missing-in-tracy-inflow-chart.pipe';
import { MITInflowResultLabelsPipe } from '../pipe/missing-in-tracy/missing-in-tracy-inflow-labels.pipe';
import { TopMITFactoryHittersResultLabelsChartPipe } from '../pipe/missing-in-tracy/missing-in-tracy-top-factory-hitters-result-labels.pipe';
import { TopMITFactoryHittersResultChartPipe } from '../pipe/missing-in-tracy/missing-in-tracy-top-factory-hitters-result.pipe';
import { TopErrorResultLabelsChartPipe } from '../pipe/top-error-result-labels.pipe';
import { TopErrorResultChartPipe } from '../pipe/top-error-result.pipe';
import { TopFactoryHittersResultLabelsChartPipe } from '../pipe/top-factory-hitters-result-labels.pipe';
import { TopFactoryHittersResultChartPipe } from '../pipe/top-factory-hitters-result.pipe';
import { ErrorHandlerService } from '../service/error-handler.service';
import { NotificationService } from '../service/notification.service';
import { TECTStateService } from '../service/tect-state.service';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [
        SpinnerLoaderComponent,
        CommonModule,
        RouterModule
    ],
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.sass'],
    providers: [
        IdleMonitorService,
        LoginService,
        StartupService,
        ApiDataService,
        FileDownloaderService,
        LoggingService,
        DatePipe,
        ErrorCategoryPipe,
        EventCodePipe,
        ErrorStatusPipe,
        TopFactoryHittersResultChartPipe,
        TopFactoryHittersResultLabelsChartPipe,
        ErrorInflowResultChartPipe,
        ErrorInflowResultLabelsPipe,
        MITInflowResultChartPipe,
        MITInflowResultLabelsPipe,
        TopErrorResultChartPipe,
        TopErrorResultLabelsChartPipe,
        TopMITFactoryHittersResultChartPipe,
        TopMITFactoryHittersResultLabelsChartPipe,
        MatBadgeModule,
        ApiDataService,
        TECTStateService,
        NotificationService,
        {
            provide: ErrorHandler,
            useClass: ErrorHandlerService
        },
        MatTableModule
    ],
})
export class AppComponent implements OnInit {
    private loginSubscription: Subscription;
    private logoutSubscription: Subscription;

    constructor(
        private startupService: StartupService,
        private router: Router,
        private loginService: LoginService,
        private idleMonitorService: IdleMonitorService
    ) {
        this.loginSubscription = this.loginService.observeLoggedIn().subscribe((identity: Identity) => {
            this.router.navigate(['/error-summary/']);
            this.idleMonitorService.start();
        });

        this.logoutSubscription = this.loginService.observeLoggedOut().subscribe(() => {
            this.idleMonitorService.stop();
            this.router.navigate(['']);
        });
    }

    ngOnInit() {
        this.startupService.setDatePeriodSearch();
        this.startupService.registerLoginObserver();
        this.loginService.setupAuthState();
    }

    ngOnDestroy() {
        // Clean up subscriptions to prevent memory leaks
        this.loginSubscription.unsubscribe();
        this.logoutSubscription.unsubscribe();
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { MITInflowSearchResult } from '../../state/missing-in-tracy/missing-in-tracy.reducers';

@Pipe({
    name: 'MITInflowResultChart',
    standalone: true
})

export class MITInflowResultChartPipe implements PipeTransform {

    constructor() { }

    transform(result: MITInflowSearchResult, ...args: any): any[] {
        let barChartData = [];
        let dataInflow = []
        let dataRemaining = [];
        let dataPercentHealed = [];
        for (let row of result.rows) {
            dataInflow.push(row.totalMIT);
            dataRemaining.push(row.remainingMIT);
            dataPercentHealed.push((Math.round(row.percentHealed * 100) / 100) + "%");
        }

        barChartData.push({
            type: 'bar',
            data: dataInflow,
            label: "Inflow",
            backgroundColor: 'rgba(255, 99, 132,0.6)',
            borderColor: 'rgba(255, 99, 132,1.0)',
            hoverBackgroundColor: 'rgba(255, 99, 132, 0.8)',
            hoverBorderColor: 'rgba(255, 99, 132, 1)'
        });
        barChartData.push({
            type: 'bar',
            data: dataRemaining,
            label: "Remaining",
            backgroundColor: 'rgba(255, 206, 86 ,0.6)',
            borderColor: 'rgba(255, 206, 86,1.0)',
            hoverBackgroundColor: 'rgba(255, 206, 86, 0.8)',
            hoverBorderColor: 'rgba(255, 206, 86, 1)'
        });
        barChartData.push({ data: dataPercentHealed, label: "Percent Healed", hidden: true }); // Data used in the tooltip therefore hidden

        return barChartData
    }
}

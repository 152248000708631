<button type="button" style="outline: none !important;" class="close" (click)="close()">&times;</button>
<mat-dialog-content style="margin-top: 20px !important">
    <div class="row">
        <mat-form-field>
            <mat-select placeholder="Event Code" [(value)]="filter.eventType"
                (selectionChange)="eventTypeChanged($event)">
                <mat-option [value]="null"><em>All Events</em></mat-option>
                <mat-option *ngFor="let eventType of eventTypes" [value]="eventType">
                    {{ eventType | eventCode}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="row">
        <p>
            <mat-form-field><input [(ngModel)]="filter.sendingUnit" oninput="this.value = this.value.toUpperCase()"
                    matInput autocomplete="off" placeholder="Sending Unit">
            </mat-form-field>
        </p>
    </div>

</mat-dialog-content>
<div style="text-align: right; padding-top: 10px;">
    <button mat-flat-button (click)="searchErrors()" class="submit-btn">Search</button>
</div>
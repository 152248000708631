import { addDays, format } from 'date-fns';
import { createReducer, on } from '@ngrx/store';
import * as DashboardActions from './dashboard.actions';
import { DashboardSearchRequest } from 'src/app/model/dashboard/dashboardSearchRequest';
import { TopErrorInflowResultRow } from "src/app/model/dashboard/topErrorInflowResultRow";
import { ErrorInflowResultRow } from 'src/app/model/dashboard/errorInflowResultRow';
import { TopFactoryHittersResultRow } from 'src/app/model/dashboard/topFactoryHittersResultRow';

// --- STATE STRUCTURE ---

export interface DashboardState {
    searchRequest: DashboardSearchRequest | null;
    topErrorsResult: TopErrorsSearchResult | null;
    errorInflowResult: ErrorInflowSearchResult | null;
    topFactoryHittersResult: TopFactoryHittersSearchResult | null;
}

export interface TopErrorsSearchResult {
    rows: TopErrorInflowResultRow[] | null;
    count: number | null;
}

export interface ErrorInflowSearchResult {
    rows: ErrorInflowResultRow[] | null;
    count: number | null;
}

export interface TopFactoryHittersSearchResult {
    rows: TopFactoryHittersResultRow[] | null;
    count: number | null;
}

const initialDashboardState: DashboardState = {
    searchRequest: {
        fromDate: format(addDays(new Date(), -1), 'yyyy-MM-dd'),
        toDate: format(new Date(), 'yyyy-MM-dd'),
        dateGrouping: 'Day',
        eventType: '',
        sendingUnit: '',
        productNumber: '',
        revisionState: '',
        errorCategory: '',
        pageSize: 100,
    },
    topErrorsResult: null,
    errorInflowResult: null,
    topFactoryHittersResult: null,
};

// --- REDUCER ---

export const dashboardReducer = createReducer(
    initialDashboardState,

    on(DashboardActions.setSearchRequests, (state, { request }) => ({
        ...state,
        searchRequest: request
    })),

    on(DashboardActions.loadTopErrorsResultsSuccess, (state, { rows, count }) => ({
        ...state,
        topErrorsResult: {
            rows,
            count: count ?? state.topErrorsResult?.count,
        }
    })),

    on(DashboardActions.loadErrorInflowResultsSuccess, (state, { rows, count }) => ({
        ...state,
        errorInflowResult: {
            rows,
            count: count ?? state.errorInflowResult?.count,
        }
    })),

    on(DashboardActions.loadTopFactoryHittersResultsSuccess, (state, { rows, count }) => ({
        ...state,
        topFactoryHittersResult: {
            rows,
            count: count ?? state.topFactoryHittersResult?.count,
        }
    })),
);

export const getDashboardState = (state: DashboardState) => state;

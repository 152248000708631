import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MITFilterSelection } from 'src/app/model/missing-in-tracy/MITFilterSelection';
import { LookupService } from 'src/app/service/lookup.service';
import { MITSearchRequest } from 'src/app/model/missing-in-tracy/MITSearchRequest';
import { CommonModule } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { EventCodePipe } from 'src/app/pipe/event-code.pipe';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        MatOptionModule,
        MatSelectModule,
        MatFormFieldModule,
        MatOptionModule,
        MatDialogModule,
        EventCodePipe,
        MatInputModule,
        FormsModule
    ],
    selector: 'app-MIT-filter',
    templateUrl: './missing-in-tracy-filter.component.html',
    styleUrls: ['./missing-in-tracy-filter.component.scss']
})
export class MITFilterComponent implements OnInit {
    public filter: MITFilterSelection;
    public eventTypes = [];

    constructor(public dialogRef: MatDialogRef<MITFilterComponent>,
        private lookupService: LookupService,
        @Inject(MAT_DIALOG_DATA) public data: MITSearchRequest) {
        this.filter = data;
    }

    ngOnInit(): void {
        this.eventTypes = this.lookupService.getEventCodes();
    }

    searchErrors() {
        this.dialogRef.close(this.filter);
    }

    close() {
        this.dialogRef.close('');
    }

    eventTypeChanged(event) {
        this.filter.eventType = event.value;
    }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ErrorSummaryRequest } from 'src/app/model/error-summary/ErrorSummaryRequest';
import { ErrorSummaryResultRow } from 'src/app/model/error-summary/ErrorSummaryResultRow';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { AppState } from 'src/app/state';
import { Store } from '@ngrx/store';
import * as ErrorSummaryActions from '../../state/error-summary/error-summary.actions';
import { Observable } from 'rxjs';
import { selectErrorSummaryRequest, selectErrorSummaryResult } from 'src/app/state/error-summary/error-summary.selectors';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { MatTableModule } from '@angular/material/table';
import { ErrorSummarySearchComponent } from './error-summary-search/error-summary-search.component';
import { ErrorCategoryPipe } from 'src/app/pipe/error-category.pipe';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    BaseChartDirective,
    MatTableModule,
    RouterModule,
    ErrorSummarySearchComponent,
    ErrorCategoryPipe,
    MatSortModule
  ],
  selector: 'app-error-summary',
  templateUrl: './error-summary.component.html',
  styleUrls: ['./error-summary.component.scss']
})
export class ErrorSummaryComponent implements OnInit {
  pageSizeOptions: number[] = [20, 50, 100];

  // NgRx Observables from store
  searchRequest$: Observable<ErrorSummaryRequest>;
  searchResults$: Observable<ErrorSummaryResultRow[]>;

  displayedColumns: string[] =
    [
      'sendingUnit',
      'errorCategory',
      'remainingErrors'
    ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private router: Router,
    private store: Store<AppState>) {
  }

  ngOnInit() {
    // NgRx observables from store
    this.searchRequest$ = this.store.select(selectErrorSummaryRequest);
    this.searchResults$ = this.store.select(selectErrorSummaryResult);
  }

  performSearch(event: ErrorSummaryRequest) {
    this.store.dispatch(ErrorSummaryActions.setSearchRequest({ request: event }));
    this.store.dispatch(ErrorSummaryActions.loadResults());
  }

  navigateToDetails(row: ErrorSummaryResultRow) {
    this.store.dispatch(ErrorSummaryActions.loadDetailsSearch({ row }));
    this.router.navigate(['error-handling']);
  }
}
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as MITActions from '../../state/missing-in-tracy/missing-in-tracy.actions';
import { Observable } from 'rxjs';
import { MITSearchRequest } from 'src/app/model/missing-in-tracy/MITSearchRequest';
import { MITInflowSearchResult, TopMITFactoryHittersSearchResult } from 'src/app/state/missing-in-tracy/missing-in-tracy.reducers';
import * as missingInTracySelectors from 'src/app/state/missing-in-tracy/missing-in-tracy.selectors';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TopMITFactoryHittersResultLabelsChartPipe } from 'src/app/pipe/missing-in-tracy/missing-in-tracy-top-factory-hitters-result-labels.pipe';
import { TopMITFactoryHittersComponent } from './missing-in-tracy-top-factory-hitters/missing-in-tracy-top-factory-hitters.component';
import { TopMITFactoryHittersResultChartPipe } from 'src/app/pipe/missing-in-tracy/missing-in-tracy-top-factory-hitters-result.pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { MITInflowComponent } from './missing-in-tracy-inflow/missing-in-tracy-inflow.component';
import { MITSearchComponent } from './missing-in-tracy-search/missing-in-tracy-search.component';
import { BaseChartDirective } from 'ng2-charts';
import { TopMITFactoryHittersBarChartComponent } from './missing-in-tracy-top-factory-hitters-bar-chart/missing-in-tracy-top-factory-hitters-bar-chart.component';
import { MITInflowResultLabelsPipe } from 'src/app/pipe/missing-in-tracy/missing-in-tracy-inflow-labels.pipe';
import { MITInflowBarChartComponent } from './missing-in-tracy-inflow-bar-chart/missing-in-tracy-inflow-bar-chart.component';
import { MITInflowResultChartPipe } from 'src/app/pipe/missing-in-tracy/missing-in-tracy-inflow-chart.pipe';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        TopMITFactoryHittersResultLabelsChartPipe,
        TopMITFactoryHittersComponent,
        TopMITFactoryHittersResultChartPipe,
        MatTabsModule,
        MITInflowComponent,
        MITSearchComponent,
        BaseChartDirective,
        TopMITFactoryHittersBarChartComponent,
        MITInflowResultLabelsPipe,
        MITInflowBarChartComponent,
        MITInflowResultChartPipe
    ],
    selector: 'app-mit',
    templateUrl: './missing-in-tracy.component.html',
    styleUrls: ['./missing-in-tracy.component.scss']
})
export class MITComponent implements OnInit {
    MITInflowResult$: Observable<MITInflowSearchResult>;
    searchRequest$: Observable<MITSearchRequest>;
    topMITFactoryHittersResult$: Observable<TopMITFactoryHittersSearchResult>;

    constructor(private store: Store<AppState>) { }

    ngOnInit(): void {
        this.MITInflowResult$ = this.store.select(missingInTracySelectors.selectMITInflowResult);
        this.searchRequest$ = this.store.select(missingInTracySelectors.selectMITSearchRequest);
        this.topMITFactoryHittersResult$ = this.store.select(missingInTracySelectors.selectTopMITFactoryHittersResult);
    }

    performSearch(event: MITSearchRequest) {
        this.store.dispatch(MITActions.setSearchRequests({ request: event }));
        this.store.dispatch(MITActions.loadMITInflowResults({ loadCount: true }));
        this.store.dispatch(MITActions.loadTopMITFactoryHittersResults({ loadCount: true }));
    }
}

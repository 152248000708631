import { createAction, props } from '@ngrx/store';

export enum NotificationActionTypes {
  ErrorNotification = '[Notification] Error Notification',
}

export const errorNotification = createAction(
  NotificationActionTypes.ErrorNotification,
  props<{ errorMessage: string }>()
);

import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { DashboardFilterSelection } from 'src/app/model/dashboard/dashboardFilterSelection';
import { DashboardSearchRequest } from 'src/app/model/dashboard/dashboardSearchRequest';
import { ErrorCategoryPipe } from 'src/app/pipe/error-category.pipe';
import { EventCodePipe } from 'src/app/pipe/event-code.pipe';
import { LookupService } from 'src/app/service/lookup.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        MatOptionModule,
        MatSelectModule,
        MatFormFieldModule,
        MatDialogModule,
        EventCodePipe,
        FormsModule,
        MatButtonModule,
        MatInputModule,
        ErrorCategoryPipe
    ],
    selector: 'app-dashboard-filter',
    templateUrl: './dashboard-filter.component.html',
    styleUrls: ['./dashboard-filter.component.scss']
})
export class DashboardFilterComponent implements OnInit {
    public filter: DashboardFilterSelection;
    public eventTypes = [];
    public errorCategories = [];
    public processSteps = [];

    constructor(public dialogRef: MatDialogRef<DashboardFilterComponent>,
        private lookupService: LookupService,
        @Inject(MAT_DIALOG_DATA) public data: DashboardSearchRequest) {
        this.filter = data;
    }

    ngOnInit(): void {
        this.eventTypes = this.lookupService.getEventCodes();
        this.errorCategories = this.lookupService.getErrorCategories();
    }

    searchErrors() {
        this.dialogRef.close(this.filter);
    }

    close() {
        this.dialogRef.close('');
    }

    eventTypeChanged(event) {
        this.filter.eventType = event.value;
    }

    errorCategoryChanged(event) {
        this.filter.errorCategory = event.value;
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from '../service/lookup.service';
import { ErrorInflowSearchResult, TopErrorsSearchResult } from '../state/dashboard/dashboard.reducers';

@Pipe({
    name: 'errorInflowResultChart',
    standalone: true
})

export class ErrorInflowResultChartPipe implements PipeTransform {

    constructor() { }

    transform(result: ErrorInflowSearchResult, ...args: any): any[] {
        let barChartData = [];
        let dataInflow = []
        let dataRemaining = [];
        let dataPercentDone = [];
        for (let row of result.rows) {
            dataInflow.push(row.errors);
            dataRemaining.push(row.remainingErrors);
            dataPercentDone.push((Math.round(row.percentDone * 100) / 100) + "%");
        }

        barChartData.push({
            type: 'bar',
            data: dataInflow, label: "Inflow",
            backgroundColor: 'rgba(255, 99, 132,0.6)',
            borderColor: 'rgba(255, 99, 132,1.0)',
            hoverBackgroundColor: 'rgba(255, 99, 132, 0.8)',
            hoverBorderColor: 'rgba(255, 99, 132, 1)'
        });
        barChartData.push({
            type: 'bar',
            data: dataRemaining, label: "Remaining",
            backgroundColor: 'rgba(255, 206, 86 ,0.6)',
            borderColor: 'rgba(255, 206, 86,1.0)',
            hoverBackgroundColor: 'rgba(255, 206, 86, 0.8)',
            hoverBorderColor: 'rgba(255, 206, 86, 1)'
        });
        barChartData.push({ data: dataPercentDone, label: "Percent Done", hidden: true }); // Data used in the tooltip therefore hidden

        return barChartData
    }
}

import { Component, Input, OnChanges } from '@angular/core';
import { ErrorRecord } from 'src/app/model/error-record/ErrorRecord';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ErrorRecordComparison } from 'src/app/model/batch-correction/ErrorRecordComparison';
import { CommonModule } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        MatTableModule,
        MatSortModule
    ],
    selector: 'app-batch-process-preview',
    templateUrl: './batch-process-preview.component.html',
    styleUrls: ['./batch-process-preview.component.scss']
})
export class BatchProcessPreviewComponent implements OnChanges {
    @Input() errors: ErrorRecord[];
    @Input() preview: ErrorRecord[];

    comparisons: ErrorRecordComparison[] = [];
    dataSource: MatTableDataSource<ErrorRecordComparison> = new MatTableDataSource<ErrorRecordComparison>(this.comparisons);
    previewReady = false;

    displayedColumns = [
        'before',
        'after'
    ];

    constructor() { }

    ngOnChanges(): void {
        this.comparisons = [];

        this.preview.forEach(preview => {
            const before: ErrorRecord | undefined = this.errors.find((error) => error.id === preview.id);
            this.comparisons.push({
                headersBefore: before?.call?.headers,
                headersAfter: preview?.call?.headers,
                endpointBefore: before?.call?.endpoint,
                endpointAfter: preview?.call?.endpoint,
                payloadBefore: before?.payload?.payload,
                payloadAfter: preview?.payload?.payload,
            });
        });
        this.dataSource.data = this.comparisons;
        this.previewReady = this.comparisons.length > 0;
    }
}

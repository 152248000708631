import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({
  providedIn: 'root'
})
export class FormlyGeneratorService {
  constructor() { }

  public generateStringField(key: string, label: string): FormlyFieldConfig {
    return {
      key,
      type: 'input',
      className: 'generated-from-json',
      templateOptions: {
        label,
        placeholder: label
      },
    } as FormlyFieldConfig;
  }

  public generateNumberField(key: string, label: string): FormlyFieldConfig {
    return {
      key,
      type: 'input',
      className: 'generated-from-json',
      templateOptions: {
        type: 'number',
        label,
        placeholder: label
      }
    } as FormlyFieldConfig;
  }

  public generateBooleanField(key: string, label: string): FormlyFieldConfig {
    return {
      key,
      type: 'checkbox',
      className: 'generated-from-json',
      templateOptions: {
        label
      }
    } as FormlyFieldConfig;
  }

  private generateFieldFromPrimitive(label: string, key: string, value: any): FormlyFieldConfig {
    if (typeof value === 'string') {
      return this.generateStringField(key, label);
    } else if (typeof value === 'number') {
      return this.generateNumberField(key, label);
    } else if (typeof value === 'boolean') {
      return this.generateBooleanField(key, label);
    } else if (value === null) {
      // Nope.
      return null;
    }

    // There are no other types of primitive values in raw JSON
  }

  public generateFormlyFields(namePrefix: string, keyPrefix: string, input: any): FormlyFieldConfig {
    const fields: FormlyFieldConfig[] = [];

    Object.entries(input).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        fields.push(...this.generateFieldsFromArray(this.joinLabels(namePrefix, key), `${keyPrefix}.${key}`, value));
      } else if (this.isObject(value)) {
        fields.push(...this.generateFieldsFromObject(this.joinLabels(namePrefix, key), `${keyPrefix}.${key}`, value));
      } else {
        fields.push(this.generateFieldFromPrimitive(this.joinLabels(namePrefix, key), `${keyPrefix}.${key}`, value));
      }
    });

    return {
      fieldGroup: fields
    };
  }

  private generateFieldsFromObject(namePrefix: string, keyPrefix: string, input: any): FormlyFieldConfig[] {
    const fields: FormlyFieldConfig[] = [];

    Object.entries(input).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        fields.push(...this.generateFieldsFromArray(this.joinLabels(namePrefix, key), `${keyPrefix}.${key}`, value));
      } else if (this.isObject(value)) {
        fields.push(...this.generateFieldsFromObject(this.joinLabels(namePrefix, key), `${keyPrefix}.${key}`, value));
      } else {
        fields.push(this.generateFieldFromPrimitive(this.joinLabels(namePrefix, key), `${keyPrefix}.${key}`, value));
      }
    });

    return fields;
  }

  private generateFieldsFromArray(namePrefix: string, keyPrefix: string, input: any[]): FormlyFieldConfig[] {
    const fields: FormlyFieldConfig[] = [];

    input.forEach((value: any, index: number) => {
      if (this.isObject(value)) {
        fields.push(...this.generateFieldsFromObject(`${namePrefix} (${index})`, `${keyPrefix}[${index}]`, value));
      } else {
        fields.push(this.generateFieldFromPrimitive(`${namePrefix} (${index})`, `${keyPrefix}[${index}]`, value));
      }
    });

    return fields;
  }

  private isObject(input: any): boolean {
    return typeof input === 'object' && input !== null;
  }

  // Done natively instead of using lodash
  private joinLabels(prefix: string, label: string): string {
    const capitalizedLabel = label
      .replace(/_/g, ' ')
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    return prefix ? `${prefix} ➔ ${capitalizedLabel}` : capitalizedLabel;
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from '../service/lookup.service';
import { ErrorInflowSearchResult, TopErrorsSearchResult } from '../state/dashboard/dashboard.reducers';

@Pipe({
    name: 'errorInflowResultLabels',
    standalone: true
})

export class ErrorInflowResultLabelsPipe implements PipeTransform {

    constructor() { }

    transform(result: ErrorInflowSearchResult, ...args: any): any[] {
        let barChartLabels = [];
        for (let row of result.rows) {
            barChartLabels.push(row.period)
        }
        return barChartLabels
    }
}

<div class="div-panel">
    <div class="filter-row">
        <div class="filter-section input-filters">
            <mat-form-field>
                <input [(ngModel)]="request.unitSnd" matInput autocomplete="off" placeholder="Sending Unit">
                <mat-label>Sending Unit</mat-label>
            </mat-form-field>
            <mat-form-field>
                <input [(ngModel)]="request.recTp" matInput autocomplete="off" placeholder="Record Type">
                <mat-label>Record Type</mat-label>
            </mat-form-field>
            <mat-form-field>
                <input [(ngModel)]="request.msgId" matInput autocomplete="off" placeholder="Error Code">
                <mat-label>Error Code</mat-label>
            </mat-form-field>
        </div>
        <div class="filter-section">
            <mat-form-field>
                <input (dateChange)="periodFromDateChange($event)" readonly="true" matInput [matDatepicker]="periodfrom"
                    placeholder="Period from" [value]="request.crStampFrom" (dateChange)="periodFromDateChange($event)">
                <mat-datepicker-toggle matSuffix [for]="periodfrom"></mat-datepicker-toggle>
                <mat-datepicker #periodfrom></mat-datepicker>
                <mat-label>Period from</mat-label>
            </mat-form-field>

            <mat-form-field>
                <input readonly="true" matInput [matDatepicker]="perioduntil" [value]="request.crStampTo"
                    (dateChange)="periodUntilDateChange($event)" placeholder="Period until">
                <mat-datepicker-toggle matSuffix [for]="perioduntil"></mat-datepicker-toggle>
                <mat-datepicker #perioduntil></mat-datepicker>
                <mat-label>Period until</mat-label>
            </mat-form-field>
        </div>

        <div class="filter-section buttons">
            <button mat-flat-button (click)="performSearch()">Search</button>
        </div>
    </div>
</div>

<!---------------------------------- Search result Table -------------------------------------------->

<div *ngIf="dataTableVisibility">
    <div style="overflow-y: auto; overflow-x: hidden; height: 65vh !important;">

        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

            <ng-container matColumnDef="serNo">
                <th mat-header-cell *matHeaderCellDef> Serial Number
                </th>
                <td mat-cell *matCellDef="let element" (click)="onRowClicked(element)">
                    <a class="text-link">{{element.serNo}}</a>
                </td>
            </ng-container>

            <ng-container matColumnDef="recTp">
                <th mat-header-cell *matHeaderCellDef> Record Type
                </th>
                <td mat-cell *matCellDef="let element">{{element.recTp}}
                </td>
            </ng-container>

            <ng-container matColumnDef="crStamp">
                <th mat-header-cell *matHeaderCellDef> Created
                </th>
                <td mat-cell *matCellDef="let element">{{element.crStamp}}
                </td>
            </ng-container>

            <ng-container matColumnDef="unitSnd">
                <th mat-header-cell *matHeaderCellDef> Sending Unit
                </th>
                <td mat-cell *matCellDef="let element">{{element.unitSnd}}
                </td>
            </ng-container>

            <ng-container matColumnDef="msgId">
                <th mat-header-cell *matHeaderCellDef> Error Code
                </th>
                <td mat-cell *matCellDef="let element">{{element.msgId}}
                </td>
            </ng-container>

            <ng-container matColumnDef="chStamp">
                <th mat-header-cell *matHeaderCellDef> Last changed
                </th>
                <td mat-cell *matCellDef="let element">{{element.chStamp}}
                </td>
            </ng-container>

            <ng-container matColumnDef="crUser">
                <th mat-header-cell *matHeaderCellDef> Created By
                </th>
                <td mat-cell *matCellDef="let element">{{element.crUser}}
                </td>
            </ng-container>

            <ng-container matColumnDef="chUser">
                <th mat-header-cell *matHeaderCellDef> Last Changed By
                </th>
                <td mat-cell *matCellDef="let element">{{element.chUser}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
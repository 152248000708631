import { createAction, props } from '@ngrx/store';
import { ErrorSummaryRequest } from 'src/app/model/error-summary/ErrorSummaryRequest';
import { ErrorSummaryResultRow } from 'src/app/model/error-summary/ErrorSummaryResultRow';

export const ErrorSummaryActionTypes = {
    LoadResults: '[Error Summary] Load Results',
    LoadResultsSuccess: '[Error Summary] Load Results Success',

    SetSearchRequest: '[Error Summary] Set Search Request',
    LoadDetailsSearch: '[Error Summary] Load Details Search',
};

export const loadResults = createAction(
    ErrorSummaryActionTypes.LoadResults
);

export const loadResultsSuccess = createAction(
    ErrorSummaryActionTypes.LoadResultsSuccess,
    props<{ results: ErrorSummaryResultRow[] }>()
);

export const setSearchRequest = createAction(
    ErrorSummaryActionTypes.SetSearchRequest,
    props<{ request: ErrorSummaryRequest }>()
);

export const loadDetailsSearch = createAction(
    ErrorSummaryActionTypes.LoadDetailsSearch,
    props<{ row: ErrorSummaryResultRow }>()
);
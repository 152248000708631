import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiDataService } from 'src/app/service/api-data.service';
import * as BatchCorrectionActions from './batch-correction.actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { MultipleErrorRequest } from 'src/app/model/error-record/MultipleErrorRequest';
import { AppState } from '..';
import { Store } from '@ngrx/store';
import { selectCorrectionSteps, selectErrorIds, selectFirstBatchForResbmission, selectNextEntryForResubmission } from './batch-correction.selectors';
import { BatchCorrectionRequest } from 'src/app/model/batch-correction/BatchCorrectionRequest';
import * as NotificationActions from '../notification/notification.actions';
import { ErrorResubmissionRequest } from 'src/app/model/error-record/ErrorResubmissionRequest';
import { of } from 'rxjs';

@Injectable()
export class BatchCorrectionEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<AppState>);
    private apiDataService = inject(ApiDataService);

    loadErrors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BatchCorrectionActions.loadErrorsForBatchCorrection),
            mergeMap(action => {
                const request = { ids: action.ids } as MultipleErrorRequest;
                return this.apiDataService.getErrorRecords(request).pipe(
                    map(result => BatchCorrectionActions.loadErrorsForBatchCorrectionSuccess({ errors: result })),
                    catchError(error => of(NotificationActions.errorNotification({ errorMessage: `Failed to load errors: ${error.message}` })))
                );
            })
        )
    );

    generatePreview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BatchCorrectionActions.generatePreview),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => {
                const steps = selectCorrectionSteps(storeState);
                const request: BatchCorrectionRequest = {
                    errorIds: selectErrorIds(storeState),
                    steps: steps,
                };
                return this.apiDataService.generateBatchCorrectionPreview(request).pipe(
                    map(result => BatchCorrectionActions.generatePreviewSuccess({ preview: result })),
                    catchError(error => of(NotificationActions.errorNotification({ errorMessage: `Failed to generate preview: ${error.message}` })))
                );
            })
        )
    );

    startBatchResubmission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BatchCorrectionActions.startBatchResubmission),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => selectFirstBatchForResbmission(storeState).map(preview =>
                BatchCorrectionActions.resubmitError({ correction: preview })
            ))
        )
    );

    resubmitError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BatchCorrectionActions.resubmitError),
            mergeMap(action => {
                const correction = action.correction;
                const request: ErrorResubmissionRequest = {
                    payload: {
                        payload: correction.payload.payload,
                    },
                    call: correction.call,
                };

                return this.apiDataService.resubmitError(correction.id, request).pipe(
                    map(() => BatchCorrectionActions.resubmitErrorSuccess({ correction })),
                    catchError(error => of(BatchCorrectionActions.resubmitErrorFailed({ resubmission: { error, correction } })))
                );
            })
        )
    );

    resubmitErrorSuccessOrFailed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BatchCorrectionActions.resubmitErrorSuccess, BatchCorrectionActions.resubmitErrorFailed),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => {
                const preview = selectNextEntryForResubmission(storeState);
                return preview === null ?
                    of(BatchCorrectionActions.batchResubmissionCompleted()) :
                    of(BatchCorrectionActions.resubmitError({ correction: preview }));
            })
        )
    );
}

import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { BaseChartDirective } from 'ng2-charts';
import { ErrorCategoryPipe } from 'src/app/pipe/error-category.pipe';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    BaseChartDirective,
    MatFormFieldModule,
    MatSelectModule,
    ErrorCategoryPipe,
    FormsModule
  ],
  selector: 'app-error-category-select',
  templateUrl: './error-category-select.component.html',
  styleUrls: ['./error-category-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorCategorySelectComponent implements OnInit {
  @Input() errorCategories: string[];
  @Input() defaultSelected: string;
  @Output() categoryChanged: EventEmitter<string> = new EventEmitter<string>();

  selected = '';
  panelClass = 'error-category-select';

  constructor() { }

  ngOnInit(): void {
    this.selected = this.defaultSelected;
  }

  valueChanged() {
    this.categoryChanged.emit(this.selected);
  }
}

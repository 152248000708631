import { CommonModule, formatDate } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, signal } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { DashboardFilterSelection } from 'src/app/model/dashboard/dashboardFilterSelection';
import { DashboardSearchRequest } from 'src/app/model/dashboard/dashboardSearchRequest';
import { ErrorCategoryPipe } from 'src/app/pipe/error-category.pipe';
import { EventCodePipe } from 'src/app/pipe/event-code.pipe';
import { LookupService } from 'src/app/service/lookup.service';
import { ErrorFilter } from '../../error-details/error-details.component';
import { DashboardFilterComponent } from '../dashboard-filter/dashboard-filter.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@Component({
    standalone: true,
    selector: 'app-dashboard-search',
    templateUrl: './dashboard-search.component.html',
    styleUrls: ['./dashboard-search.component.scss'],
    imports: [
        CommonModule,
        MatDialogModule,
        ErrorCategoryPipe,
        EventCodePipe,
        DashboardFilterComponent,
        MatInputModule,
        MatDatepickerModule,
        MatTooltipModule,
        MatOptionModule,
        MatSelectModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule
    ]
})
export class DashboardSearchComponent implements OnInit {
    @Input() set initialSearchRequest(value: DashboardSearchRequest) {
        this.searchRequest = {
            ...value
        } as DashboardSearchRequest;

        this.refreshErrorFilter();
    }

    @Output() performSearch = new EventEmitter<DashboardSearchRequest>();

    public currentDate: string;
    public dateGroupingOptions = [];
    public searchRequest: DashboardSearchRequest;
    filterArray = signal<ErrorFilter[]>([]);

    constructor(
        private lookupService: LookupService,
        private dialog: MatDialog,
        private errorCategoryPipe: ErrorCategoryPipe,
        private eventCodePipe: EventCodePipe) {
        this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }

    ngOnInit(): void {
        this.dateGroupingOptions = this.lookupService.getDateGroupingOptions();
    }

    toDateChange(event) {
        this.searchRequest.toDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
    }

    fromDateChange(event) {
        this.searchRequest.fromDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
    }

    dateGroupingOptionChanged(event) {
        this.searchRequest.dateGrouping = event.value;
    }

    searchErrors() {
        this.performSearch.emit(this.searchRequest);
    }

    showFilterDialog() {
        const currentSearchRequest = this.searchRequest;

        try {
            const dialogRef = this.dialog.open(DashboardFilterComponent, {
                width: "100vw",
                minHeight: '70%',
                maxHeight: '80%',
                maxWidth: '800px',
                data: currentSearchRequest
            });
            dialogRef.afterClosed().subscribe((response: DashboardFilterSelection) => {
                if (response) {
                    this.searchRequest = {
                        ...this.searchRequest,
                        ...response
                    };

                    this.refreshErrorFilter();
                    this.searchErrors();
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    public refreshErrorFilter() {
        this.filterArray.set([]);

        if (this.searchRequest.eventType) {
            this.filterArray.update(filters => [
                ...filters,
                { name: 'eventType', value: `Event code: ${this.eventCodePipe.transform(this.searchRequest.eventType)}` }
            ]);
        }

        if (this.searchRequest.sendingUnit) {
            this.filterArray.update(filters => [
                ...filters,
                { name: 'sendingUnit', value: `Sending unit: ${this.searchRequest.sendingUnit}` }
            ]);
        }

        if (this.searchRequest.errorCategory) {
            this.filterArray.update(filters => [
                ...filters,
                { name: 'errorCategory', value: `Error category: ${this.errorCategoryPipe.transform(this.searchRequest.errorCategory)}` }
            ]);
        }

        if (this.searchRequest.productNumber) {
            this.filterArray.update(filters => [
                ...filters,
                { name: 'productNumber', value: `Product number: ${this.searchRequest.productNumber}` }
            ]);
        }

        if (this.searchRequest.revisionState) {
            this.filterArray.update(filters => [
                ...filters,
                { name: 'revisionState', value: `Revision state: ${this.searchRequest.revisionState}` }
            ]);
        }

        if (this.searchRequest.fromDate) {
            this.filterArray.update(filters => [
                ...filters,
                { name: 'fromDate', value: `From: ${this.searchRequest.fromDate}` }
            ]);
        }

        if (this.searchRequest.toDate) {
            this.filterArray.update(filters => [
                ...filters,
                { name: 'toDate', value: `To: ${this.searchRequest.toDate}` }
            ]);
        }
    }

    removeFromErrorFilter(filterParam: ErrorFilter) {
        const index = this.filterArray().findIndex(x => x.name === filterParam.name);

        if (index >= 0) {
            this.filterArray.update(filters => {
                const newFilters = [...filters];
                newFilters.splice(index, 1);
                return newFilters;
            });
            this.searchRequest[filterParam.name] = null;
        }
    }

    removeFromSearch(searchParam: ErrorFilter) {
        this.removeFromErrorFilter(searchParam);
        this.searchErrors();
    }
}

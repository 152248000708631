<div class="div-panel sticky-top">
    <div class="row">
        <div class="col-md-6">
            <mat-form-field>
                <input matInput [(ngModel)]="searchValue" type="text" placeholder="Product/Serial Number"
                    aria-label="Search" (keyup.enter)="searchErrorRecords()">
                <mat-label>Product/Serial Number</mat-label>
            </mat-form-field>
            <mat-form-field *ngIf="!canReadAllErrors">
                <mat-select placeholder="Sending unit" [(value)]="errorRecordSearchRequest.sendingUnit">
                    <mat-option *ngFor="let unit of userUnits" [value]="unit">
                        {{ unit }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-checkbox (change)="loadCount.set($event.checked)">
                With total
            </mat-checkbox>
            <button mat-flat-button style="margin: 1em" (click)=searchErrorRecords()>
                <i class="fa fa-search"></i>
            </button>
        </div>

        <div class="col-md-4">
            <mat-chip-set>
                @for (errorFilter of errorFilterArray(); track errorFilter.name) {
                <mat-chip matTooltip="{{errorFilter.description}}" (removed)="removeFromSearch(errorFilter)">
                    {{errorFilter.value}}
                    <mat-icon matChipRemove [attr.aria-label]="'remove ' + errorFilter.name">cancel</mat-icon>
                </mat-chip>
                }
            </mat-chip-set>
        </div>

        <div class="col-md-2" style="text-align: right;">
            <label (click)=showErrorFilterDialog() style="cursor: pointer;">
                <i class="fa fa-filter" style="font-size: 20px; margin-top: 10px;"></i> Filter
            </label>
        </div>
    </div>
</div>
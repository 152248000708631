import { enableProdMode, importProvidersFrom } from '@angular/core';
import { AppComponent } from './app/components/app.component';
import environment from './environments/static';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideStore } from '@ngrx/store';
import { reducers } from './app/state';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { routes } from './app/app-routing.module';
import { DateAdapter, NativeDateAdapter, provideNativeDateAdapter } from '@angular/material/core';
import { interceptorFn } from './app/interceptor/interceptor';
import { provideEffects } from '@ngrx/effects';
import { ErrorDetailsEffects } from './app/state/error-details/error-details.effects';
import { ErrorSummaryEffects } from './app/state/error-summary/error-summary.effects';
import { DashboardEffects } from './app/state/dashboard/dashboard.effects';
import { NavigationEffects } from './app/state/navigation/navigation.effects';
import { BatchCorrectionEffects } from './app/state/batch-correction/batch-correction.effects';
import { NotificationEffects } from './app/state/notification/notification.effects';
import { ErrorUpdateEffects } from './app/state/error-update/error-update.effects';
import { MITEffects } from './app/state/missing-in-tracy/missing-in-tracy.effects';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyFieldInput } from '@ngx-formly/material/input';
import { RepeatSectionComponent } from './app/components/formly/repeat-section/repeat-section.component';
import { FormlyFieldCheckbox } from '@ngx-formly/material/checkbox';

if (environment.compiled) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideCharts(withDefaultRegisterables()),
    provideHttpClient(withInterceptors([interceptorFn])),
    provideStore(reducers),
    provideEffects(
      ErrorDetailsEffects,
      ErrorSummaryEffects,
      DashboardEffects,
      NavigationEffects,
      BatchCorrectionEffects,
      NotificationEffects,
      ErrorUpdateEffects,
      MITEffects
    ),
    provideAnimations(),
    provideRouter(routes),
    { provide: DateAdapter, useClass: NativeDateAdapter },
    provideNativeDateAdapter(),
    importProvidersFrom(
      FormlyModule.forRoot({
        types: [
          { name: 'input', component: FormlyFieldInput },
          { name: 'repeat', component: RepeatSectionComponent },
          { name: 'checkbox', component: FormlyFieldCheckbox }
        ]
      }),
    ),
  ],
}).catch(err => console.error(err));

import { ErrorRecord } from "src/app/model/error-record/ErrorRecord";
import { createReducer, on } from '@ngrx/store';
import * as ErrorUpdateActions from './error-update.actions';

export interface ErrorUpdateState {
    error: ErrorRecord | null;
}

const initialErrorUpdateState: ErrorUpdateState = {
    error: null,
};

export const errorUpdateReducer = createReducer(
    initialErrorUpdateState,
    on(ErrorUpdateActions.loadErrorSuccess, (state, { errorRecord }) => ({
        ...state,
        error: errorRecord,
    })),
    on(ErrorUpdateActions.updateErrorSuccess, (state) => ({
        ...state,
        error: null,
    })),
    on(ErrorUpdateActions.archiveErrorSuccess, (state) => ({
        ...state,
        error: null,
    })),
    on(ErrorUpdateActions.unarchiveErrorSuccess, (state) => ({
        ...state,
        error: null,
    })),
    on(ErrorUpdateActions.updateErrorFailed, (state, { resubmission }) => {
        if (resubmission.error.status === 400) {
            const newError = resubmission.error.error as ErrorRecord;
            return {
                ...state,
                error: newError,
            };
        }
        return state;
    })
);

import { Component, OnInit, ViewChild } from '@angular/core';
import { Policies } from 'src/constants';
import { ErrorSearchResultRow } from 'src/app/model/error-search/ErrorSearchResultRow';
import { ErrorRecordSearchRequest } from 'src/app/model/error-search/ErrorRecordSearchRequest';
import { LoginService } from 'src/app/service/login.service';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as ErrorDetailsActions from '../../state/error-details/error-details.actions';
import { ErrorDetailsSearchResult } from 'src/app/state/error-details/error-details.reducers';
import { Observable } from 'rxjs';
import {
  selectErrorDetailsRequest,
  selectErrorDetailsResult,
  selectErrorDetailsPageIndex,
  selectErrorDetailsPageSize,
  selectErrorDetailsSelectedRows,
  selectErrorDetailsAllOnScreenSelected,
  selectErrorDetailsAllRowsSelected,
  selectErrorDetailsCanBatchCorrect,
  selectErrorDetailsCanSelectAll,
  selectErrorDetailsSelectedCount
} from 'src/app/state/error-details/error-details.selectors';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { ErrorDetailsSearchComponent } from './error-details-search/error-details-search.component';
import { RouterModule } from '@angular/router';
import { ErrorStatusPipe } from 'src/app/pipe/error-status.pipe';
import { EventCodePipe } from 'src/app/pipe/event-code.pipe';
import { ErrorCategoryPipe } from 'src/app/pipe/error-category.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatPaginatorModule,
    BaseChartDirective,
    MatTableModule,
    MatChipsModule,
    ErrorDetailsSearchComponent,
    RouterModule,
    EventCodePipe,
    ErrorCategoryPipe,
    MatCheckboxModule,
    MatButtonModule,
    MatSortModule
  ],
  providers: [
    ErrorStatusPipe
  ],
  selector: 'app-error-list',
  templateUrl: './error-details.component.html',
  styleUrls: ['./error-details.component.scss']
})
export class ErrorDetailsComponent implements OnInit {

  pageSizeOptions: number[] = [20, 50, 100, 500, 1000];

  displayedColumns: string[] =
    [
      'archiveCheckbox',
      'serialNumberReferences',
      'productNumberReferences',
      'events',
      'sendingUnit',
      'errorCategories',
      'creationDate',
    ];

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  // NgRx Observables from store
  searchRequest$: Observable<ErrorRecordSearchRequest>;
  searchResults$: Observable<ErrorDetailsSearchResult>;
  pageIndex$: Observable<number>;
  pageSize$: Observable<number>;
  selectedRows$: Observable<boolean[]>;
  allOnScreenSelected$: Observable<boolean>;
  allRowsSelected$: Observable<boolean>;
  canBatchCorrect$: Observable<boolean>;
  canSelectAll$: Observable<boolean>;
  selectedCount$: Observable<number>;

  constructor(
    private loginService: LoginService,
    private store: Store<AppState>) {
  }

  ngOnInit() {
    // NgRx observables from store
    this.searchRequest$ = this.store.select(selectErrorDetailsRequest);
    this.searchResults$ = this.store.select(selectErrorDetailsResult);
    this.pageIndex$ = this.store.select(selectErrorDetailsPageIndex);
    this.pageSize$ = this.store.select(selectErrorDetailsPageSize);
    this.selectedRows$ = this.store.select(selectErrorDetailsSelectedRows);
    this.allOnScreenSelected$ = this.store.select(selectErrorDetailsAllOnScreenSelected);
    this.allRowsSelected$ = this.store.select(selectErrorDetailsAllRowsSelected);
    this.canBatchCorrect$ = this.store.select(selectErrorDetailsCanBatchCorrect);
    this.canSelectAll$ = this.store.select(selectErrorDetailsCanSelectAll);
    this.selectedCount$ = this.store.select(selectErrorDetailsSelectedCount);

    if (!this.loginService.acceptedByPolicy(Policies.CanUpdateErrors)) {
      this.displayedColumns.shift();
    }
  }

  performSearch(event: ErrorRecordSearchRequest) {
    // Set the search request in the store. Then dispatch actions to perform the
    // search.
    this.store.dispatch(ErrorDetailsActions.setSearchRequest({ request: event }));
    this.store.dispatch(ErrorDetailsActions.loadResults({ loadCount: event.loadCount }));
  }

  onErrorRowClicked(currentRow: ErrorSearchResultRow) {
    this.store.dispatch(ErrorDetailsActions.updateError({ id: currentRow.id }));
  }

  exportErrorsToExcel() {
    this.store.dispatch(ErrorDetailsActions.exportToExcel());
  }

  tableRowChecked(index: number) {
    this.store.dispatch(ErrorDetailsActions.toggleRowSelection({ index }));
  }

  selectAllCheckBoxes() {
    this.store.dispatch(ErrorDetailsActions.selectAllRows());
  }

  toggleAllOnScreenSelected() {
    this.store.dispatch(ErrorDetailsActions.toggleSelectAllVisible());
  }

  archiveSelectedErrors() {
    this.store.dispatch(ErrorDetailsActions.archiveErrors());
  }

  batchCorrectCheckedErrors() {
    this.store.dispatch(ErrorDetailsActions.startBatchCorrection());
  }

  loadMoreRows(event?: PageEvent) {
    this.store.dispatch(
      ErrorDetailsActions.paginate({
        pageIndex: event.pageIndex,
        pageSize: event.pageSize
      })
    );
    this.store.dispatch(ErrorDetailsActions.loadResults({ loadCount: false }));

    return event;
  }

  get allowedToBatchCorrect(): boolean {
    return this.loginService.acceptedByPolicy(Policies.CanDoBatchCorrection);
  }
}

export interface ErrorFilter {
  name: string;
  value: string;
  description?: string;
}

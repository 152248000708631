<div *ngIf="topMITFactoryHittersResult?.rows?.length > 0" style="overflow-y: auto; overflow-x: hidden; height: 65vh;">
    <mat-chip style="margin: 1em;">
        <span>
            Showing <strong>{{ topMITFactoryHittersResult.rows.length }}</strong> of
            <strong>{{ topMITFactoryHittersResult.count | number: '1.0' }}</strong> records
        </span>
    </mat-chip>

    <mat-table [dataSource]="topMITFactoryHittersResult.rows" matSort class="mat-elevation-z8">
        <!-- Sending Unit Column -->
        <ng-container matColumnDef="sendingUnit">
            <mat-header-cell *matHeaderCellDef> Sending Unit </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.sendingUnit }} </mat-cell>
        </ng-container>

        <!-- MITs Column -->
        <ng-container matColumnDef="mits">
            <mat-header-cell *matHeaderCellDef> MITs </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.mit }} </mat-cell>
        </ng-container>

        <!-- Header and Row Declarations -->
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</div>
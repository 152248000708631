import { createAction, props } from '@ngrx/store';
import { ErrorRecordSearchRequest } from 'src/app/model/error-search/ErrorRecordSearchRequest';
import { ErrorSearchResultRow } from 'src/app/model/error-search/ErrorSearchResultRow';

export enum ErrorDetailsActionTypes {
  LoadResults = '[Error Details] Load Results',
  LoadResultsSuccess = '[Error Details] Load Results Success',
  Paginate = '[Error Details] Paginate',

  ArchiveErrors = '[Error Details] Archive Errors',
  ArchiveErrorsSuccess = '[Error Details] Archive Errors Success',
  ArchiveAllErrors = '[Error Details] Archive All Errors',
  ArchiveAllErrorsSuccess = '[Error Details] Archive All Errors Success',

  ToggleRowSelection = '[Error Details] Toggle Row Selection',
  ToggleSelectAllVisible = '[Error Details] Toggle Select All Visible',
  SelectAllRows = '[Error Details] Select All Rows',
  ClearSelection = '[Error Details] Clear Selection',

  ExportToExcel = '[Error Details] Export To Excel',
  ExportToExcelSuccess = '[Error Details] Export To Excel Success',

  SetSearchRequest = '[Error Details] Set Search Request',
  StartBatchCorrection = '[Error Details] Start Batch Correction',
  UpdateError = '[Error Details] Update Error',
}

export const loadResults = createAction(
  ErrorDetailsActionTypes.LoadResults,
  props<{ loadCount: boolean }>()
);

export const loadResultsSuccess = createAction(
  ErrorDetailsActionTypes.LoadResultsSuccess,
  props<{
    rows: ErrorSearchResultRow[],
    count: number | null
  }>()
);

export const paginate = createAction(
  ErrorDetailsActionTypes.Paginate,
  props<{
    pageSize: number,
    pageIndex: number
  }>()
);

export const archiveErrors = createAction(
  ErrorDetailsActionTypes.ArchiveErrors
);

export const archiveErrorsSuccess = createAction(
  ErrorDetailsActionTypes.ArchiveErrorsSuccess
);

export const archiveAllErrors = createAction(
  ErrorDetailsActionTypes.ArchiveAllErrors
);

export const archiveAllErrorsSuccess = createAction(
  ErrorDetailsActionTypes.ArchiveAllErrorsSuccess
);

export const toggleRowSelection = createAction(
  ErrorDetailsActionTypes.ToggleRowSelection,
  props<{ index: number }>()
);

export const toggleSelectAllVisible = createAction(
  ErrorDetailsActionTypes.ToggleSelectAllVisible
);

export const selectAllRows = createAction(
  ErrorDetailsActionTypes.SelectAllRows
);

export const clearSelection = createAction(
  ErrorDetailsActionTypes.ClearSelection
);

export const exportToExcel = createAction(
  ErrorDetailsActionTypes.ExportToExcel
);

export const exportToExcelSuccess = createAction(
  ErrorDetailsActionTypes.ExportToExcelSuccess
);

export const setSearchRequest = createAction(
  ErrorDetailsActionTypes.SetSearchRequest,
  props<{ request: ErrorRecordSearchRequest }>()
);

export const startBatchCorrection = createAction(
  ErrorDetailsActionTypes.StartBatchCorrection
);

export const updateError = createAction(
  ErrorDetailsActionTypes.UpdateError,
  props<{ id: number }>()
);

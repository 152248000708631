import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { NotificationService } from 'src/app/service/notification.service';
import * as ErrorNotificationActions from './notification.actions';

@Injectable()
export class NotificationEffects {
  private actions$ = inject(Actions);
  private notificationService = inject(NotificationService);

  errorNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorNotificationActions.errorNotification), // Updated to use the new action creator
      tap(action => {
        // Show the error notification
        this.notificationService.showError(action.errorMessage); // Adjusted to access the error message directly
      })
    ),
    { dispatch: false } // Indicates this effect does not dispatch any actions
  );
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { LegacyError } from 'src/app/model/legacy-errors/LegacyError';
import { DisplayFieldComponent } from '../generic/display-field/display-field.component';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-legacy-error-display',
  templateUrl: './legacy-error-display.component.html',
  styleUrls: ['./legacy-error-display.component.scss'],
  imports: [
    DisplayFieldComponent,
    CommonModule,
    RouterModule
  ],
})
export class LegacyErrorDisplayComponent implements OnInit {
  public legacyError: LegacyError | null = null;
  private subscription: Subscription | null = null;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.subscription = this.route.data.subscribe((data) => {
      this.legacyError = data.legacyError as LegacyError || null;
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, of } from 'rxjs';
import { mergeMap, withLatestFrom, map, catchError } from 'rxjs/operators';
import { ErrorCount } from 'src/app/model/error-search/ErrorCount';
import { ApiDataService } from 'src/app/service/api-data.service';
import { AppState } from '..';
import * as ErrorNotificationActions from '../notification/notification.actions';
import * as BatchCorrectionActions from '../batch-correction/batch-correction.actions';
import * as NavigationActions from '../navigation/navigation.actions';
import * as ErrorDetailsActions from './error-details.actions';
import {
  selectErrorDetailsRequest,
  selectErrorDetailsCountRequest,
  selectArchiveAllErrorsRequest,
  selectArchiveRequest,
  selectExportToExcelRequest,
  selectErrorDetailsSelectedIds
} from './error-details.selectors';
import * as ErrorUpdateActions from '../error-update/error-update.actions';

@Injectable()
export class ErrorDetailsEffects {
  private actions$ = inject(Actions);
  private store$ = inject(Store<AppState>);
  private apiDataService = inject(ApiDataService);

  loadResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorDetailsActions.loadResults),
      withLatestFrom(this.store$),
      mergeMap(([action, storeState]) => {
        return forkJoin({
          searchResult: this.apiDataService.searchErrorRecords(selectErrorDetailsRequest(storeState)),
          countResult: action.loadCount
            ? this.apiDataService.errorCount(selectErrorDetailsCountRequest(storeState))
            : of({ count: null } as ErrorCount),
        }).pipe(
          map(({ searchResult, countResult }) => ErrorDetailsActions.loadResultsSuccess({ rows: searchResult, count: countResult.count })),
          catchError((error) => of(ErrorNotificationActions.errorNotification({ errorMessage: `Failed to load search: ${error.message}` })))
        );
      })
    )
  );

  archiveAllErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorDetailsActions.archiveAllErrors),
      withLatestFrom(this.store$),
      mergeMap(([, storeState]) =>
        this.apiDataService.archiveAllSelectedErrors(selectArchiveAllErrorsRequest(storeState)).pipe(
          map(() => ErrorDetailsActions.archiveAllErrorsSuccess()),
          catchError((error) => of(ErrorNotificationActions.errorNotification({ errorMessage: `Failed to archive errors: ${error.message}` })))
        )
      )
    )
  );

  archiveErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorDetailsActions.archiveErrors),
      withLatestFrom(this.store$),
      mergeMap(([, storeState]) =>
        this.apiDataService.archiveError(selectArchiveRequest(storeState)).pipe(
          map(() => ErrorDetailsActions.archiveErrorsSuccess()),
          catchError((error) => of(ErrorNotificationActions.errorNotification({ errorMessage: `Failed to archive errors: ${error.message}` })))
        )
      )
    )
  );

  archiveErrorsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ErrorDetailsActions.archiveErrorsSuccess,
        ErrorDetailsActions.archiveAllErrorsSuccess
      ),
      mergeMap(() => of(ErrorDetailsActions.loadResults({ loadCount: true })))
    )
  );

  excelExport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorDetailsActions.exportToExcel),
      withLatestFrom(this.store$),
      mergeMap(([, storeState]) =>
        this.apiDataService.exportErrorsToExcel(selectExportToExcelRequest(storeState)).pipe(
          map(() => ErrorDetailsActions.exportToExcelSuccess()),
          catchError((error) =>
            of(
              ErrorNotificationActions.errorNotification({
                errorMessage: `Unable to export error records. HTTP response code: ${error.status}`
              })
            )
          )
        )
      )
    )
  );

  startBatchCorrection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorDetailsActions.startBatchCorrection),
      withLatestFrom(this.store$),
      mergeMap(([, storeState]) => {
        const ids = selectErrorDetailsSelectedIds(storeState);
        return [
          BatchCorrectionActions.loadErrorsForBatchCorrection({ ids }),
          NavigationActions.navigateToPage({ route: ['error-handling', 'batch-correct'] }),
        ];
      })
    )
  );

  updateError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorDetailsActions.updateError),
      mergeMap((action) => [ErrorUpdateActions.loadError({ id: action.id })])
    )
  );
}

import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { forkJoin, of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { ErrorRecordSearchRequest } from "src/app/model/error-search/ErrorRecordSearchRequest";
import { ApiDataService } from "src/app/service/api-data.service";
import { AppState } from "..";
import { formatDate } from '@angular/common';
import * as NotificationActions from "../notification/notification.actions";
import * as DashboardActions from "./dashboard.actions";
import {
    selectDashboardSearchRequest,
    selectErrorInflowRequest,
    selectExportErrorInflowToExcelRequest,
    selectExportTopErrorsToExcelRequest,
    selectExportTopFactoryHittersToExcelRequest,
    selectTopErrorsRequest,
    selectTopFactoryHittersRequest
} from "./dashboard.selectors";
import * as ErrorDetailsActions from '../error-details/error-details.actions';
import { ErrorCount } from "src/app/model/error-search/ErrorCount";

@Injectable()
export class DashboardEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<AppState>);
    private apiDataService = inject(ApiDataService);

    loadTopErrorsResult$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.loadTopErrorsResults),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                return forkJoin({
                    searchResult: this.apiDataService.searchDashboardTopErrors(selectTopErrorsRequest(storeState)),
                    countResult: action.loadCount
                        ? this.apiDataService.topErrorCount(selectTopErrorsRequest(storeState))
                        : of({ count: null } as ErrorCount),
                }).pipe(
                    map(({ searchResult, countResult }) =>
                        DashboardActions.loadTopErrorsResultsSuccess({ rows: searchResult, count: countResult.count })
                    ),
                    catchError((error) =>
                        of(NotificationActions.errorNotification({ errorMessage: `Failed to load errors: ${error.message}` }))
                    )
                );
            })
        )
    );

    /**
     * When a LoadDetailsSearchAction is dispatched, create an error details request with parameters
     * that match the row in the action. Dispatch it to the store, then start the error details search
     * by dispatching an DashboardActionTypes.LoadResultsAction.
     */
    searchDetailsTopErrors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.loadDetailsTopErrors),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                const request = selectDashboardSearchRequest(storeState);
                const clickedRow = action.row;

                const detailsSearchRequest = new ErrorRecordSearchRequest();
                detailsSearchRequest.errorCategory = clickedRow.errorCategory;
                detailsSearchRequest.sendingUnit = request.sendingUnit;
                detailsSearchRequest.eventCode = request.eventType;
                detailsSearchRequest.toDate = request.toDate;
                detailsSearchRequest.fromDate = request.fromDate;
                detailsSearchRequest.searchText = request.productNumber;
                detailsSearchRequest.revisionState = request.revisionState;
                detailsSearchRequest.status = 'New';

                return [
                    ErrorDetailsActions.setSearchRequest({ request: detailsSearchRequest }),
                    ErrorDetailsActions.loadResults({ loadCount: true })
                ];
            })
        )
    );

    searchDetailsErrorInflow$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.loadDetailsErrorInflow),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                const request = selectDashboardSearchRequest(storeState);
                const clickedRow = action.row;

                const detailsSearchRequest = new ErrorRecordSearchRequest();
                detailsSearchRequest.sendingUnit = request.sendingUnit;
                detailsSearchRequest.eventCode = request.eventType;
                detailsSearchRequest.toDate = formatDate(clickedRow.endOfPeriod, 'yyyy-MM-dd', 'en');
                detailsSearchRequest.fromDate = formatDate(clickedRow.startOfPeriod, 'yyyy-MM-dd', 'en');
                detailsSearchRequest.errorCategory = request.errorCategory;
                detailsSearchRequest.searchText = request.productNumber;
                detailsSearchRequest.revisionState = request.revisionState;
                detailsSearchRequest.status = 'New';

                return [
                    ErrorDetailsActions.setSearchRequest({ request: detailsSearchRequest }),
                    ErrorDetailsActions.loadResults({ loadCount: true })
                ];
            })
        )
    );

    exportTopErrorsToExcel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.exportTopErrorsToExcel),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) =>
                this.apiDataService.exportDashboardTopErrorsToExcel(selectExportTopErrorsToExcelRequest(storeState)).pipe(
                    map(() => DashboardActions.exportTopErrorsToExcelSuccess()),
                    catchError((error) =>
                        of(NotificationActions.errorNotification({ errorMessage: `Unable to export top errors. HTTP response code: ${error.status}` }))
                    )
                )
            )
        )
    );

    exportErrorInflowToExcel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.exportErrorInflowToExcel),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) =>
                this.apiDataService.exportDashboardErrorInflowToExcel(selectExportErrorInflowToExcelRequest(storeState)).pipe(
                    map(() => DashboardActions.exportErrorInflowToExcelSuccess()),
                    catchError((error) =>
                        of(NotificationActions.errorNotification({ errorMessage: `Unable to export error inflow. HTTP response code: ${error.status}` }))
                    )
                )
            )
        )
    );

    loadErrorInflowResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.loadErrorInflowResults),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                return forkJoin({
                    searchResult: this.apiDataService.searchDashboardErrorInflow(selectErrorInflowRequest(storeState)),
                    countResult: action.loadCount
                        ? this.apiDataService.errorInflowCount(selectErrorInflowRequest(storeState))
                        : of({ count: null } as ErrorCount)
                }).pipe(
                    map(({ searchResult, countResult }) => {
                        return DashboardActions.loadErrorInflowResultsSuccess({ rows: searchResult, count: countResult.count });
                    }),
                    catchError((error) => of(NotificationActions.errorNotification({ errorMessage: `Failed to load errors: ${error.message}` })))
                );
            })
        )
    );

    loadTopFactoryHittersResult$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.loadTopFactoryHittersResults),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                return forkJoin({
                    searchResult: this.apiDataService.searchDashboardTopFactoryHitters(selectTopFactoryHittersRequest(storeState)),
                    countResult: action.loadCount
                        ? this.apiDataService.topFactoryHittersCount(selectTopFactoryHittersRequest(storeState))
                        : of({ count: null } as ErrorCount)
                }).pipe(
                    map(({ searchResult, countResult }) => {
                        return DashboardActions.loadTopFactoryHittersResultsSuccess({ rows: searchResult, count: countResult.count });
                    }),
                    catchError((error) => of(NotificationActions.errorNotification({ errorMessage: `Failed to load factory hitters: ${error.message}` })))
                );
            })
        )
    );

    searchDetailsTopFactoryHitters$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.loadDetailsSearchTopFactoryHitters),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                const request = selectDashboardSearchRequest(storeState);
                const clickedRow = action.row;

                const detailsSearchRequest = new ErrorRecordSearchRequest();
                detailsSearchRequest.sendingUnit = clickedRow.sendingUnit;
                detailsSearchRequest.eventCode = request.eventType;
                detailsSearchRequest.toDate = request.toDate;
                detailsSearchRequest.fromDate = request.fromDate;

                return [
                    ErrorDetailsActions.setSearchRequest({ request: detailsSearchRequest }),
                    ErrorDetailsActions.loadResults({ loadCount: true })
                ];
            })
        )
    );

    exportTopFactoryHittersToExcel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.exportTopFactoryHittersToExcel),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) =>
                this.apiDataService.exportDashboardTopFactoryHittersToExcel(selectExportTopFactoryHittersToExcelRequest(storeState)).pipe(
                    map(() => DashboardActions.exportTopFactoryHittersToExcelSuccess()),
                    catchError((error) =>
                        of(NotificationActions.errorNotification({ errorMessage: `Unable to export top factory hitters. HTTP response code: ${error.status}` }))
                    )
                )
            )
        )
    );
}

import { Pipe, PipeTransform } from '@angular/core';
import { MITInflowSearchResult } from '../../state/missing-in-tracy/missing-in-tracy.reducers';

@Pipe({
    name: 'MITInflowResultLabels',
    standalone: true
})

export class MITInflowResultLabelsPipe implements PipeTransform {

    constructor() { }

    transform(result: MITInflowSearchResult, ...args: any): any[] {
        let barChartLabels = [];
        for (let row of result.rows) {
            barChartLabels.push(row.period)
        }
        return barChartLabels
    }
}

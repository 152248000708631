import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ChartOptions, CategoryScale, LinearScale, Chart } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    standalone: true,
    imports: [BaseChartDirective],
    selector: 'app-top-errors-bar-chart',
    templateUrl: './top-errors-bar-chart.component.html',
    styleUrls: ['./top-errors-bar-chart.component.scss']
})
export class TopErrorsBarChartComponent implements OnInit {
    constructor() {
        Chart.register(CategoryScale, LinearScale);
    }

    public barChartOptions: ChartOptions = {
        scales: {
            x: {
                type: 'category',
            },
            y: {
                stacked: false,
                beginAtZero: true
            }
        },
        responsive: true,
        maintainAspectRatio: true
    };

    @Input() barChartData: any[];
    @Input() barChartLabels: string[];

    public barChartLegend = true;

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
    }
}

<div *ngIf="errorInflowResult.rows.length > 0" style="overflow-y: auto; overflow-x: hidden; height: 65vh !important;">
    <button mat-flat-button type="button" style="margin: 1em" (click)="exportErrorInflowToExcel()">Export</button>
    <mat-chip color="primary">
        <span color="primary">Showing
            <strong>{{errorInflowResult.rows.length}}</strong> of <strong>{{errorInflowResult.count |
                number:'1.0'}}</strong>
            records</span>
    </mat-chip>

    <table mat-table [dataSource]="errorInflowResult.rows" matSort matSort class="mat-elevation-z8">
        <ng-container matColumnDef="period">
            <th mat-header-cell *matHeaderCellDef> Period </th>
            <td mat-cell *matCellDef="let element">
                {{ element.period }}
            </td>
        </ng-container>

        <ng-container matColumnDef="errors">
            <th mat-header-cell *matHeaderCellDef> Inflow </th>
            <td mat-cell *matCellDef="let element">
                {{element.errors}}
            </td>
        </ng-container>

        <ng-container matColumnDef="remainingErrors">
            <th mat-header-cell *matHeaderCellDef> Remaining </th>
            <td mat-cell *matCellDef="let element">
                {{element.remainingErrors}}
            </td>
        </ng-container>

        <ng-container matColumnDef="percentDone">
            <th mat-header-cell *matHeaderCellDef> % Done </th>
            <td mat-cell *matCellDef="let element">
                {{element.percentDone | number : '1.2-2'}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToDetails(row)"></tr>
    </table>
</div>
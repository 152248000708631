import { createAction, props } from '@ngrx/store';
import { ErrorRecord } from 'src/app/model/error-record/ErrorRecord';
import { FailedResubmission } from 'src/app/model/error-record/FailedResubmission';
import { CorrectionStep } from '../../model/batch-correction/CorrectionStep';

export enum BatchCorrectionActionTypes {
    AddStep = '[Batch Correct] Add Step',
    RemoveStep = '[Batch Correct] Remove Step',
    LoadErrorsForBatchCorrection = '[Batch Correct] Load Errors',
    LoadErrorsForBatchCorrectionSuccess = '[Batch Correct] Load Errors Success',
    GeneratePreview = '[Batch Correct] Generate Preview',
    GeneratePreviewSuccess = '[Batch Correct] Generate Preview Success',

    StartBatchResubmission = '[Batch Correct] Start Batch Resubmission',
    ResubmitError = '[Batch Correct] Resubmit Error',
    ResubmitErrorSuccess = '[Batch Correct] Resubmit Error Success',
    ResubmitErrorFailed = '[Batch Correct] Resubmit Error Failed',
    BatchResubmissionCompleted = '[Batch Correct] Batch Resubmission Completed',
}

export const addStep = createAction(
    BatchCorrectionActionTypes.AddStep,
    props<{ step: CorrectionStep }>()
);

export const removeStep = createAction(
    BatchCorrectionActionTypes.RemoveStep,
    props<{ step: CorrectionStep }>()
);

export const loadErrorsForBatchCorrection = createAction(
    BatchCorrectionActionTypes.LoadErrorsForBatchCorrection,
    props<{ ids: number[] }>()
);

export const loadErrorsForBatchCorrectionSuccess = createAction(
    BatchCorrectionActionTypes.LoadErrorsForBatchCorrectionSuccess,
    props<{ errors: ErrorRecord[] }>()
);

export const generatePreview = createAction(
    BatchCorrectionActionTypes.GeneratePreview
);

export const generatePreviewSuccess = createAction(
    BatchCorrectionActionTypes.GeneratePreviewSuccess,
    props<{ preview: ErrorRecord[] }>()
);

export const startBatchResubmission = createAction(
    BatchCorrectionActionTypes.StartBatchResubmission
);

export const resubmitError = createAction(
    BatchCorrectionActionTypes.ResubmitError,
    props<{ correction: ErrorRecord }>()
);

export const resubmitErrorSuccess = createAction(
    BatchCorrectionActionTypes.ResubmitErrorSuccess,
    props<{ correction: ErrorRecord }>()
);

export const resubmitErrorFailed = createAction(
    BatchCorrectionActionTypes.ResubmitErrorFailed,
    props<{ resubmission: FailedResubmission }>()
);

export const batchResubmissionCompleted = createAction(
    BatchCorrectionActionTypes.BatchResubmissionCompleted
);

<div class="div-panel sticky-top">
    <div class="row">
        <div class="col-md-3">
            <div class="row">
                <mat-form-field class="input-with-datepicker">
                    <input readonly="true" (dateChange)="fromDateChange($event)" matInput [matDatepicker]="fromdate"
                        placeholder="Period from" [value]="searchRequest.fromDate"
                        [max]="searchRequest.toDate || currentDate">
                    <mat-datepicker-toggle matSuffix [for]="fromdate">
                    </mat-datepicker-toggle>
                    <mat-datepicker #fromdate></mat-datepicker>
                    <mat-label>Period from</mat-label>
                </mat-form-field>
            </div>

            <div class="row">
                <mat-form-field class="input-with-datepicker">
                    <input readonly="true" (dateChange)="toDateChange($event)" matInput [matDatepicker]="todate"
                        placeholder="Period until" [value]="searchRequest.toDate" [min]="searchRequest.fromDate"
                        [max]="currentDate">
                    <mat-datepicker-toggle matSuffix [for]="todate">
                    </mat-datepicker-toggle>
                    <mat-datepicker #todate></mat-datepicker>
                    <mat-label>Period until</mat-label>
                </mat-form-field>
            </div>
        </div>

        <div class="col-md-3">
            <mat-form-field>
                <mat-select placeholder="Group by" (selectionChange)="dateGroupingOptionChanged($event)"
                    [(value)]=searchRequest.dateGrouping>
                    <mat-option *ngFor="let option of dateGroupingOptions" [value]="option">
                        {{ option }}
                    </mat-option>
                </mat-select>
                <mat-label>Group by</mat-label>
            </mat-form-field>
        </div>

        <div class="col-md-3">
            <button mat-flat-button (click)="searchErrors()" class="submit-btn">Search</button>
        </div>

        <div class="col-md-3">
            <mat-chip-set #chipList>
                <mat-chip-option style="font-size: 12px; background-color:#47bac1; color:#fff;"
                    matTooltip="{{filter.description}}" *ngFor="let filter of filterArray">
                    {{filter.value}}
                    <i matChipRemove style="color:#fff;" (click)="removeFromSearch(filter)"
                        class="fa fa-times-circle"></i>
                </mat-chip-option>
            </mat-chip-set>
        </div>
    </div>
</div>
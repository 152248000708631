import { createReducer, on } from '@ngrx/store';
import { addMonths, format } from 'date-fns';
import { ErrorSummaryRequest } from 'src/app/model/error-summary/ErrorSummaryRequest';
import { ErrorSummaryResultRow } from 'src/app/model/error-summary/ErrorSummaryResultRow';
import * as ErrorSummaryActions from './error-summary.actions';

// --- STATE STRUCTURE ---

export interface ErrorSummaryState {
    request: ErrorSummaryRequest | null;
    results: ErrorSummaryResultRow[] | null;
}

const initialErrorSummaryState: ErrorSummaryState = {
    request: {
        sendingUnit: '',
        pageSize: 100,
        fromDate: format(addMonths(new Date(), -1), 'yyyy-MM-dd'),
        toDate: format(new Date(), 'yyyy-MM-dd'),
    } as ErrorSummaryRequest,
    results: null,
};

// --- REDUCER ---

export const errorSummaryReducer = createReducer(
    initialErrorSummaryState,
    on(ErrorSummaryActions.loadResultsSuccess, (state, { results }) => ({
        ...state,
        results: results
    })),
    on(ErrorSummaryActions.setSearchRequest, (state, { request }) => ({
        ...state,
        request: request
    }))
);

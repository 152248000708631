import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { ArchiveErrorRequest } from "src/app/model/error-record/ArchiveErrorRequest";
import { ErrorRecord } from "src/app/model/error-record/ErrorRecord";
import { ApiDataService } from "src/app/service/api-data.service";
import { AppState } from "..";
import { loadResults } from "../error-details/error-details.actions";
import { navigateToPage } from "../navigation/navigation.actions";
import { errorNotification } from "../notification/notification.actions";
import * as ErrorUpdateActions from "./error-update.actions";
import { selectError } from "./error-update.selectors";

const rxAddChildren = /^\/api\/public\/v1\/individual\/([^\/]+)\/children$/;
const rxRemoveChildren = /^\/api\/public\/v1\/individual\/([^\/]+)\/children\/to-decouple$/;

function patchPayload(errorRecord: ErrorRecord, patcher: (payload: any) => any): ErrorRecord {
    const realPayload = {
        ...errorRecord.payload.payload,
        ...patcher(errorRecord.payload.payload)
    };

    return {
        ...errorRecord,
        payload: {
            ...errorRecord.payload,
            payload: {
                ...realPayload
            }
        }
    };
}

function patchAndHack(errorRecord: ErrorRecord): ErrorRecord {
    if (!errorRecord.call || !errorRecord.payload || !errorRecord.payload.payload) {
        return errorRecord;
    }

    const shouldPatch =
        (errorRecord.call.method === "POST" && errorRecord.call.endpoint === "/api/public/v1/individual") || // B-record
        (errorRecord.call.method === "POST" && rxAddChildren.test(errorRecord.call.endpoint)) || // SA-record
        (errorRecord.call.method === "DELETE" && rxRemoveChildren.test(errorRecord.call.endpoint)); // SD-record

    if (shouldPatch) {
        return patchPayload(errorRecord, payload => ({
            ...payload,
            childSerialNumbers: payload.childSerialNumbers?.map(csn => csn?.trim()).filter(csn => !!csn)
        }));
    }

    return errorRecord;
}

@Injectable()
export class ErrorUpdateEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<AppState>);
    private apiDataService = inject(ApiDataService);

    loadError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ErrorUpdateActions.loadError),
            mergeMap(action =>
                this.apiDataService.getErrorRecord(action.id).pipe(
                    mergeMap(result => [
                        navigateToPage({ route: [`error-handling/${result.id}`] }),
                        ErrorUpdateActions.loadErrorSuccess({ errorRecord: result })
                    ]),
                    catchError(error =>
                        of(errorNotification({ errorMessage: `Failed to load error: ${error.message}` }))
                    )
                )
            )
        )
    );

    updateError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ErrorUpdateActions.updateError),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                const errorRecord = patchAndHack(selectError(storeState));
                return this.apiDataService.resubmitError(errorRecord.id, action.resubmissionRequest).pipe(
                    mergeMap(() => [
                        ErrorUpdateActions.updateErrorSuccess(),
                        loadResults({ loadCount: true })
                    ]),
                    catchError(error =>
                        of(ErrorUpdateActions.updateErrorFailed({ resubmission: { error, correction: error.error } }))
                    )
                );
            })
        )
    );

    handleError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ErrorUpdateActions.updateErrorFailed),
            map(() =>
                errorNotification({ errorMessage: 'Tracy rejected the resubmission. Correct the errors and try again.' })
            )
        )
    );

    archiveError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ErrorUpdateActions.archiveError),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => {
                const errorRecord = selectError(storeState);
                const request: ArchiveErrorRequest = { errorIds: [errorRecord.id] };
                return this.apiDataService.archiveError(request).pipe(
                    mergeMap(() => [
                        ErrorUpdateActions.archiveErrorSuccess(),
                        loadResults({ loadCount: true })
                    ]),
                    catchError(error =>
                        of(errorNotification({ errorMessage: `Failed to archive error: ${error.message}` }))
                    )
                );
            })
        )
    );

    unarchiveError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ErrorUpdateActions.unarchiveError),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => {
                const errorRecord = selectError(storeState);
                return this.apiDataService.unarchiveError(errorRecord.id).pipe(
                    mergeMap(() => [
                        ErrorUpdateActions.unarchiveErrorSuccess(),
                        loadResults({ loadCount: true })
                    ]),
                    catchError(error =>
                        of(errorNotification({ errorMessage: `Failed to unarchive error: ${error.message}` }))
                    )
                );
            })
        )
    );

    sendEmail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ErrorUpdateActions.sendEmail),
            withLatestFrom(this.store$),
            mergeMap(([, storeState]) => {
                const errorRecord = selectError(storeState);
                return this.apiDataService.sendEmail(errorRecord.id).pipe(
                    mergeMap(() => [
                        ErrorUpdateActions.sendEmailSuccess(),
                        loadResults({ loadCount: true })
                    ]),
                    catchError(error =>
                        of(errorNotification({ errorMessage: `Failed to send email: ${error.message}` }))
                    )
                );
            })
        )
    );

    navigateBack$ = createEffect(() =>
        this.actions$.pipe(
            ofType(
                ErrorUpdateActions.archiveErrorSuccess,
                ErrorUpdateActions.updateErrorSuccess,
                ErrorUpdateActions.unarchiveErrorSuccess,
                ErrorUpdateActions.sendEmailSuccess
            ),
            map(() => navigateToPage({ route: ['error-handling'] }))
        ), { dispatch: false }
    );
}

import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ChartOptions, CategoryScale, LinearScale, Chart } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    standalone: true,
    imports: [BaseChartDirective],
    selector: 'app-error-inflow-bar-chart',
    templateUrl: './error-inflow-bar-chart.component.html',
    styleUrls: ['./error-inflow-bar-chart.component.scss']
})
export class ErrorInflowBarChartComponent implements OnInit {
    constructor() {
        Chart.register(CategoryScale, LinearScale);
    }

    public barChartOptions: ChartOptions = {
        scales: {
            x: {
                type: 'category',
            },
            y: {
                stacked: false,
                beginAtZero: true,
            },
        },
        plugins: {
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (tooltipItem) {
                        const dataIndex = tooltipItem.dataIndex;
                        const inflow = tooltipItem.chart.data.datasets[0].data[dataIndex];
                        const remaining = tooltipItem.chart.data.datasets[1].data[dataIndex];
                        const percentDone = tooltipItem.chart.data.datasets[2].data[dataIndex];
                        return `Inflow: ${inflow} Remaining: ${remaining} Percent Done: ${percentDone}`;
                    },
                },
            },
            legend: {
                labels: {
                    filter: function (item) {
                        return !item.text.includes('Percent Done');
                    },
                },
            },
        },
        responsive: true,
        maintainAspectRatio: true,
    };

    @Input() barChartData: any[];
    @Input() barChartLabels: string[];
    @Input() barChartRemaining: any[];

    public barChartLegend = false;

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
    }
}

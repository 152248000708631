import { createAction, props } from '@ngrx/store';
import { TopErrorInflowResultRow } from "src/app/model/dashboard/topErrorInflowResultRow";
import { DashboardSearchRequest } from "src/app/model/dashboard/dashboardSearchRequest";
import { ErrorInflowResultRow } from "src/app/model/dashboard/errorInflowResultRow";
import { TopFactoryHittersResultRow } from "src/app/model/dashboard/topFactoryHittersResultRow";

export enum DashboardActionTypes {
    LoadDetailsSearchTopErrors = '[Dashboard] Load Details Search Top Errors',
    LoadDetailsSearchErrorInflow = '[Dashboard] Load Details Search Error Inflow',
    LoadTopErrorsResults = '[Dashboard] Load Top Errors Results',
    LoadTopErrorsResultsSuccess = '[Dashboard] Load Top Errors Results Success',
    ExportErrorInflowToExcel = '[Dashboard] Export Error Inflow To Excel',
    ExportErrorInflowToExcelSuccess = '[Dashboard] Export Error Inflow To Excel Success',
    ExportTopErrorsToExcel = '[Dashboard] Export Top Errors To Excel',
    ExportTopErrorsToExcelSuccess = '[Dashboard] Export Top Errors To Excel Success',
    LoadErrorInflowResults = '[Dashboard] Load Error Inflow Results',
    LoadErrorInflowResultsSuccess = '[Dashboard] Load Error Inflow Results Success',
    LoadTopFactoryHittersResults = '[Dashboard] Load Top Factory Hitters Results',
    LoadTopFactoryHittersResultsSuccess = '[Dashboard] Load Top Factory Hitters Results Success',
    LoadDetailsSearchTopFactoryHitters = '[Dashboard] Load Details Search Top Factory Hitters',
    ExportTopFactoryHittersToExcel = '[Dashboard] Export Top Factory Hitters To Excel',
    ExportTopFactoryHittersToExcelSuccess = '[Dashboard] Export Top Factory Hitters To Excel Success',
    SetSearchRequests = '[Dashboard] Set Search Requests',
};

export const setSearchRequests = createAction(
    DashboardActionTypes.SetSearchRequests,
    props<{ request: DashboardSearchRequest }>()
);

export const loadTopErrorsResults = createAction(
    DashboardActionTypes.LoadTopErrorsResults,
    props<{ loadCount: boolean }>()
);

export const loadTopErrorsResultsSuccess = createAction(
    DashboardActionTypes.LoadTopErrorsResultsSuccess,
    props<{
        rows: TopErrorInflowResultRow[],
        count: number | null
    }>()
);

export const exportTopErrorsToExcel = createAction(
    DashboardActionTypes.ExportTopErrorsToExcel
);

export const exportErrorInflowToExcel = createAction(
    DashboardActionTypes.ExportErrorInflowToExcel
);

export const exportErrorInflowToExcelSuccess = createAction(
    DashboardActionTypes.ExportErrorInflowToExcelSuccess
);

export const exportTopErrorsToExcelSuccess = createAction(
    DashboardActionTypes.ExportTopErrorsToExcelSuccess
);

export const loadErrorInflowResults = createAction(
    DashboardActionTypes.LoadErrorInflowResults,
    props<{ loadCount: boolean }>()
);

export const loadErrorInflowResultsSuccess = createAction(
    DashboardActionTypes.LoadErrorInflowResultsSuccess,
    props<{
        rows: ErrorInflowResultRow[],
        count: number | null
    }>()
);

export const loadDetailsTopErrors = createAction(
    DashboardActionTypes.LoadDetailsSearchTopErrors,
    props<{ row: TopErrorInflowResultRow }>()
);

export const loadDetailsErrorInflow = createAction(
    DashboardActionTypes.LoadDetailsSearchErrorInflow,
    props<{ row: ErrorInflowResultRow }>()
);

export const loadTopFactoryHittersResults = createAction(
    DashboardActionTypes.LoadTopFactoryHittersResults,
    props<{ loadCount: boolean }>()
);

export const loadTopFactoryHittersResultsSuccess = createAction(
    DashboardActionTypes.LoadTopFactoryHittersResultsSuccess,
    props<{
        rows: TopFactoryHittersResultRow[],
        count: number | null
    }>()
);

export const loadDetailsSearchTopFactoryHitters = createAction(
    DashboardActionTypes.LoadDetailsSearchTopFactoryHitters,
    props<{ row: TopFactoryHittersResultRow }>()
);

export const exportTopFactoryHittersToExcel = createAction(
    DashboardActionTypes.ExportTopFactoryHittersToExcel
);

export const exportTopFactoryHittersToExcelSuccess = createAction(
    DashboardActionTypes.ExportTopFactoryHittersToExcelSuccess
);
import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { forkJoin, of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { ApiDataService } from "src/app/service/api-data.service";
import { AppState } from "..";
import * as ErrorNotificationAction from "../notification/notification.actions";
import { ErrorCount } from "src/app/model/error-search/ErrorCount";
import * as MissingInTracyActions from "./missing-in-tracy.actions";
import { selectMITInflowRequest, selectTopMITFactoryHittersRequest } from "./missing-in-tracy.selectors";

@Injectable()
export class MITEffects {
    private actions$ = inject(Actions);
    private store$ = inject(Store<AppState>);
    private apiDataService = inject(ApiDataService);

    loadMITInflowResults$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissingInTracyActions.loadMITInflowResults),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                const { loadCount } = action;

                return this.apiDataService.searchMITInflow(selectMITInflowRequest(storeState)).pipe(
                    mergeMap((searchResult) => {
                        const countObservable = loadCount
                            ? this.apiDataService.MITInflowCount(selectMITInflowRequest(storeState))
                            : of({ count: null } as ErrorCount);

                        return countObservable.pipe(
                            map((countResult) => {
                                return MissingInTracyActions.loadMITInflowResultsSuccess({ rows: searchResult, count: countResult.count });
                            })
                        );
                    }),
                    catchError((error) => {
                        return of(ErrorNotificationAction.errorNotification({ errorMessage: `Failed to load errors: ${error.message}` }));
                    })
                );
            })
        )
    );

    loadTopMITFactoryHittersResult$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MissingInTracyActions.loadTopMITFactoryHittersResults),
            withLatestFrom(this.store$),
            mergeMap(([action, storeState]) => {
                const { loadCount } = action;
                return forkJoin({
                    searchResult: this.apiDataService.searchTopMITFactoryHitters(selectTopMITFactoryHittersRequest(storeState)),
                    countResult: loadCount ?
                        this.apiDataService.topMITFactoryHittersCount(selectTopMITFactoryHittersRequest(storeState)) :
                        of({ count: null } as ErrorCount)
                }).pipe(
                    map(({ searchResult, countResult }) => {
                        return MissingInTracyActions.loadTopMITFactoryHittersResultsSuccess({ rows: searchResult, count: countResult.count }); // Use the action creator
                    }),
                    catchError((error) => of(ErrorNotificationAction.errorNotification({ errorMessage: `Failed to load errors: ${error.message}` }))) // Use the action creator
                );
            })
        )
    );
}

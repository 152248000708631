import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { ErrorRecord } from 'src/app/model/error-record/ErrorRecord';
import { CorrectionStep } from 'src/app/model/batch-correction/CorrectionStep';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as BatchCorrectionActions from 'src/app/state/batch-correction/batch-correction.actions';
import * as ErrorDetailsActions from 'src/app/state/error-details/error-details.actions';
import { Observable } from 'rxjs';
import { selectCorrectionFieldTypeValues, selectCorrectionSteps, selectErrors, selectPreview } from 'src/app/state/batch-correction/batch-correction.selectors';
import { CorrectionFieldType } from 'src/app/model/batch-correction/CorrectionFieldType';
import { CommonModule } from '@angular/common';
import { BatchProcessCompletionComponent } from '../batch-process-completion/batch-process-completion.component';
import { MatStepperModule } from '@angular/material/stepper';
import { BatchProcessPreviewComponent } from '../batch-process-preview/batch-process-preview.component';
import { BatchProcessStepFormComponent } from '../batch-process-step-form/batch-process-step-form.component';
import { MatIconModule } from '@angular/material/icon';
import { BatchProcessStepDisplayComponent } from '../batch-process-step-display/batch-process-step-display.component';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    BatchProcessCompletionComponent,
    MatStepperModule,
    BatchProcessPreviewComponent,
    BatchProcessStepFormComponent,
    MatIconModule,
    BatchProcessStepDisplayComponent,
    MatButtonModule
  ],
  selector: 'app-batch-process-stepper',
  templateUrl: './batch-process-stepper.component.html',
  styleUrls: ['./batch-process-stepper.component.scss'],
})
export class BatchProcessStepperComponent implements OnInit {
  isAddingStep = true;

  correctionSteps$: Observable<CorrectionStep[]>;
  errors$: Observable<ErrorRecord[]>;
  fieldTypeValues$: Observable<CorrectionFieldType>;
  preview$: Observable<ErrorRecord[]>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.correctionSteps$ = this.store.select(selectCorrectionSteps);
    this.errors$ = this.store.select(selectErrors);
    this.fieldTypeValues$ = this.store.select(selectCorrectionFieldTypeValues);
    this.preview$ = this.store.select(selectPreview);
  }

  onRemoveStep(step: CorrectionStep) {
    this.store.dispatch(BatchCorrectionActions.removeStep({ step }));
  }

  startAddingStep() {
    this.isAddingStep = true;
  }

  addNewStep(step: CorrectionStep) {
    this.store.dispatch(BatchCorrectionActions.addStep({ step }));
    this.stopAddingStep();
  }

  stopAddingStep() {
    this.isAddingStep = false;
  }

  onStepChange(event: StepperSelectionEvent) {
    if (event.selectedIndex === 1 && event.previouslySelectedIndex === 0) {
      this.store.dispatch(BatchCorrectionActions.generatePreview());
    } else if (event.selectedIndex === 2 && event.previouslySelectedIndex === 1) {
      this.store.dispatch(BatchCorrectionActions.startBatchResubmission());
    }
  }

  navigateAway() {
    this.store.dispatch(ErrorDetailsActions.loadResults({ loadCount: true }));
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}

import { HttpRequest, HttpHandlerFn, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { SpinnerLoaderService } from '../service/spinner-loader.service';
import { LoginService } from '../service/login.service';
import { ApiUriService } from '../service/api-uri.service';

export function interceptorFn(req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> {
    const loaderService = inject(SpinnerLoaderService);
    const loginService = inject(LoginService);
    const apiUriService = inject(ApiUriService);

    const addToLoadCount = req.url !== apiUriService.renewCookieUrl && req.url !== apiUriService.checkCookieUrl;

    if (addToLoadCount) loaderService.show();

    const newRequest = req.clone({
        headers: req.headers.set('Cache-Control', 'no-cache'), // Prevent caching
        withCredentials: true, // Include credentials like cookies
    });

    return next(newRequest).pipe(
        tap((evt) => {
            if (evt instanceof HttpResponse) {
                loaderService.hide();
            }
        }),
        catchError((error: HttpErrorResponse) => {
            if ([401, 403].includes(error.status)) {
                loginService.logout();
                loaderService.hide();
            } else if (addToLoadCount) {
                loaderService.hide();
            }
            return throwError(() => error);
        })
    );
}

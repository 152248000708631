import { Component, EventEmitter, Input, OnInit, Output, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorRecordSearchRequest } from 'src/app/model/error-search/ErrorRecordSearchRequest';
import { ErrorCategoryPipe } from 'src/app/pipe/error-category.pipe';
import { ErrorFilter } from '../error-details.component';
import { ErrorFilterComponent, ErrorFilterSelection } from '../error-filter/error-filter.component';
import { EventCodePipe } from 'src/app/pipe/event-code.pipe';
import { ErrorStatusPipe } from 'src/app/pipe/error-status.pipe';
import { LoginService } from 'src/app/service/login.service';
import { Policies } from 'src/constants';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ErrorDetailsEffects } from 'src/app/state/error-details/error-details.effects';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        MatTooltipModule,
        MatInputModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule
    ],
    providers: [
        ErrorDetailsEffects,
        ErrorCategoryPipe,
        ErrorStatusPipe,
        EventCodePipe,
    ],
    selector: 'app-error-details-search',
    templateUrl: './error-details-search.component.html',
    styleUrls: ['./error-details-search.component.scss']
})
export class ErrorDetailsSearchComponent implements OnInit {
    @Input() set initialSearchRequest(value: ErrorRecordSearchRequest) {
        // We must spread the initial search request into a new object.
        // The search request comes directly from the store, and as such has
        // readonly properties. It can not be modified (remember, immutability!).
        this.errorRecordSearchRequest = {
            ...value
        } as ErrorRecordSearchRequest;

        this.searchValue = this.errorRecordSearchRequest.searchText ?? '';

        // Refresh the chips showing the current search parameters
        this.refreshErrorFilter();

        this.loadCount.set(this.errorRecordSearchRequest.loadCount);
    }

    @Output() performSearch = new EventEmitter<ErrorRecordSearchRequest>();

    searchValue = '';
    errorFilterArray = signal<ErrorFilter[]>([]);
    loadCount = signal<boolean>(false);
    public errorRecordSearchRequest: ErrorRecordSearchRequest;
    public userUnits?: string[];
    public readonly canReadAllErrors: boolean;

    constructor(
        private loginService: LoginService,
        private dialog: MatDialog,
        private errorCategoryPipe: ErrorCategoryPipe,
        private errorStatusPipe: ErrorStatusPipe,
        private eventCodePipe: EventCodePipe,
        private snackBar: MatSnackBar) {

        this.canReadAllErrors = this.loginService.acceptedByPolicy(Policies.CanReadAllErrors);
    }

    ngOnInit(): void {
        this.userUnits = this.loginService.currentIdentity.units;
    }

    searchErrorRecords() {
        if (!this.canReadAllErrors && !this.errorRecordSearchRequest.sendingUnit) {
            this.snackBar.open('A sending unit must first be selected.', 'OK');
        } else {
            this.errorRecordSearchRequest.searchText = this.searchValue?.toUpperCase();
            this.errorRecordSearchRequest.pageIndex = 0;
            this.errorRecordSearchRequest.loadCount = this.loadCount();
            this.performSearch.emit(this.errorRecordSearchRequest);
        }
    }

    showErrorFilterDialog() {
        const currentErrorRecordSearchRequest = this.errorRecordSearchRequest;
        try {
            const dialogRef = this.dialog.open(ErrorFilterComponent, {
                width: "100vw",
                minHeight: '70%',
                maxHeight: '80%',
                maxWidth: '800px',
                data: currentErrorRecordSearchRequest
            });
            dialogRef.afterClosed().subscribe((response: ErrorFilterSelection) => {
                if (response) {
                    this.errorRecordSearchRequest = {
                        ...this.errorRecordSearchRequest,
                        ...response
                    };

                    this.refreshErrorFilter();
                    this.searchErrorRecords();
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    public refreshErrorFilter() {
        this.errorFilterArray.set([]);

        if (this.errorRecordSearchRequest.unitReference) {
            this.errorFilterArray.update(filters => [
                ...filters,
                { name: 'unitReference', value: `Referenced Unit: ${this.errorRecordSearchRequest.unitReference}` }
            ]);
        }

        if (this.errorRecordSearchRequest.sendingUnit) {
            this.errorFilterArray.update(filters => [
                ...filters,
                { name: 'sendingUnit', value: `Sending unit: ${this.errorRecordSearchRequest.sendingUnit}` }
            ]);
        }

        if (this.errorRecordSearchRequest.errorCode) {
            this.errorFilterArray.update(filters => [
                ...filters,
                { name: 'errorCode', value: `Discovery code: ${this.errorRecordSearchRequest.errorCode}` }
            ]);
        }

        if (this.errorRecordSearchRequest.fromDate) {
            this.errorFilterArray.update(filters => [
                ...filters,
                { name: 'fromDate', value: `From: ${this.errorRecordSearchRequest.fromDate}` }
            ]);
        }

        if (this.errorRecordSearchRequest.toDate) {
            this.errorFilterArray.update(filters => [
                ...filters,
                { name: 'toDate', value: `To: ${this.errorRecordSearchRequest.toDate}` }
            ]);
        }

        if (this.errorRecordSearchRequest.status) {
            this.errorFilterArray.update(filters => [
                ...filters,
                { name: 'status', value: `Status: ${this.errorStatusPipe.transform(this.errorRecordSearchRequest.status)}` }
            ]);
        }

        if (this.errorRecordSearchRequest.errorCategory) {
            this.errorFilterArray.update(filters => [
                ...filters,
                { name: 'errorCategory', value: `Category: ${this.errorCategoryPipe.transform(this.errorRecordSearchRequest.errorCategory)}` }
            ]);
        }

        if (this.errorRecordSearchRequest.eventCode) {
            this.errorFilterArray.update(filters => [
                ...filters,
                { name: 'eventCode', value: `Event code: ${this.eventCodePipe.transform(this.errorRecordSearchRequest.eventCode)}` }
            ]);
        }
    }

    removeFromErrorFilter(filterParam: ErrorFilter) {
        const index = this.errorFilterArray().findIndex(x => x.name === filterParam.name);

        if (index >= 0) {
            this.errorFilterArray.update(filters => {
                const newFilters = [...filters];
                newFilters.splice(index, 1);
                return newFilters;
            });
            this.errorRecordSearchRequest[filterParam.name] = null;
        }
    }

    removeFromSearch(searchParam: ErrorFilter) {
        this.removeFromErrorFilter(searchParam);
        this.searchErrorRecords();
    }
}

import { Injectable, signal, computed } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SpinnerLoaderService {
    private loadCount = signal(0);

    readonly isLoading = computed(() => this.loadCount() > 0);

    show() {
        this.loadCount.set(this.loadCount() + 1);
    }

    hide() {
        if (this.loadCount() > 0) {
            this.loadCount.set(this.loadCount() - 1);
        }
    }
}

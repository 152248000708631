<div class="div-panel sticky-top">
    <div class="row">
        <div class="col">
            <div class="col">
                <div class="row">
                    <mat-form-field class="input-with-datepicker">
                        <input readonly="true" (dateChange)="fromDateChange($event)" matInput [matDatepicker]="fromdate"
                            placeholder="Period from" [value]="searchRequest.fromDate"
                            [max]="searchRequest.toDate || currentDate">
                        <mat-datepicker-toggle matSuffix [for]="fromdate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #fromdate></mat-datepicker>
                        <mat-label>Period from</mat-label>
                    </mat-form-field>
                </div>

                <div class="row">
                    <mat-form-field class="input-with-datepicker">
                        <input readonly="true" (dateChange)="toDateChange($event)" matInput [matDatepicker]="todate"
                            placeholder="Period until" [value]="searchRequest.toDate" [min]="searchRequest.fromDate"
                            [max]="currentDate">
                        <mat-datepicker-toggle matSuffix [for]="todate">
                        </mat-datepicker-toggle>
                        <mat-datepicker #todate></mat-datepicker>
                        <mat-label>Period until</mat-label>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="col">
            <mat-form-field>
                <mat-select placeholder="Group by" (selectionChange)="dateGroupingOptionChanged($event)"
                    [(value)]=searchRequest.dateGrouping>
                    <mat-option *ngFor="let option of dateGroupingOptions" [value]="option">
                        {{ option }}
                    </mat-option>
                </mat-select>
                <mat-label>Group by</mat-label>
            </mat-form-field>
        </div>

        <div class="col">
            <button mat-flat-button (click)="searchErrors()" class="submit-btn">Search</button>
        </div>

    </div>
    <div class="row">
        <div class="col">
            <mat-chip-set>
                @for (filter of filterArray(); track filter.name) {
                <mat-chip matTooltip="{{filter.description}}" (removed)="removeFromSearch(filter)">
                    {{filter.value}}
                    <mat-icon matChipRemove [attr.aria-label]="'remove ' + filter.name">cancel</mat-icon>
                </mat-chip>
                }
            </mat-chip-set>
        </div>

        <div class="col" style="text-align: right; cursor: pointer !important;">
            <label (click)=showFilterDialog() style="cursor: pointer !important;"> <i class="fa fa-filter"
                    style="font-size: 20px; margin-top: 10px;"></i> Filter</label>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { TECTStateService } from './tect-state.service';
import { ApiDataService } from './api-data.service';
import { evaluatePolicy } from 'src/constants';
import environmentStatic from '../../environments/static';

export interface Identity {
    username: string;
    roles: string[];
    units?: string[];
}

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    private loginSubject = new Subject<Identity>();
    private logoutSubject = new Subject<void>();

    constructor(
        private stateService: TECTStateService,
        private apiDataService: ApiDataService
    ) { }

    observeLoggedIn(): Observable<Identity> {
        return this.loginSubject.asObservable();
    }

    observeLoggedOut(): Observable<void> {
        return this.logoutSubject.asObservable();
    }

    async setupAuthState(): Promise<void> {
        try {
            const cookieResult = await this.apiDataService.checkCookie();
            if (cookieResult.success) {
                const identity = {
                    username: cookieResult.username,
                    roles: cookieResult.roles,
                    units: cookieResult.units,
                };
                this.stateService.setErrorMessage(null);
                this.stateService.setCurrentIdentity(identity);
                this.notifyLoginObservers(identity);
            } else {
                this.stateService.setCurrentIdentity(null);
                if (environmentStatic.tracyFrontend === null) {
                    this.stateService.setErrorMessage('[DEV] Please log in');
                } else {
                    window.location.href = environmentStatic.tracyFrontend;
                }
            }
        } catch (error) {
            console.error('Error checking cookie:', error);
            // Handle error (e.g., show a message to the user)
        }
    }

    async renewToken(): Promise<void> {
        try {
            const response = await this.apiDataService.renewToken();
            const identity = {
                username: response.username,
                roles: response.roles,
                units: response.units,
            };
            this.stateService.setCurrentIdentity(identity);
        } catch (error) {
            console.error('Error renewing token:', error);
            // Handle error as needed
        }
    }

    private notifyLoginObservers(identity: Identity) {
        this.loginSubject.next(identity);
    }

    private notifyLogoutObservers() {
        this.logoutSubject.next();
    }

    logout(): void {
        this.notifyLogoutObservers();
        this.stateService.setCurrentIdentity(null);
        this.browserPost(environmentStatic.tracyLogoutUrl, {});
    }

    acceptedByPolicy(policy: string[]): boolean {
        return evaluatePolicy(this.currentIdentity, policy);
    }

    performLogin(username: string): void {
        this.browserPost(this.webApiUrl('authentication/web-user'), {
            username: username,
            replyUrl: this.replyUrlForAuthentication()
        });

        // Notify observers after a successful login
        const identity: Identity = { username, roles: [], units: [] };
        this.notifyLoginObservers(identity);
    }

    get currentIdentity(): Identity | null {
        return this.stateService.getCurrentIdentity();
    }

    private browserPost(path: string | URL, params: any): void {
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('action', path.toString());

        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                const hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);
                form.appendChild(hiddenField);
            }
        }

        document.body.appendChild(form);
        form.submit();
    }

    private webApiUrl(path: string): string {
        while (path.length && path.startsWith('/')) {
            path = path.substring(1);
        }

        return environmentStatic.apiBaseUrl + path;
    }

    private replyUrlForAuthentication(): string {
        // Construct reply URL based on current URL.
        const currentUrl = new URL(window.location.href);
        return `${currentUrl.protocol}//${currentUrl.hostname}${(currentUrl.port ? ':' + currentUrl.port : '')}/`;
    }
}
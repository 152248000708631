import { Pipe, PipeTransform } from '@angular/core';
import { TopMITFactoryHittersSearchResult } from 'src/app/state/missing-in-tracy/missing-in-tracy.reducers';

@Pipe({
    name: 'topMITFactoryHittersResultChart',
    standalone: true
})
export class TopMITFactoryHittersResultChartPipe implements PipeTransform {
    constructor() { }

    transform(result: TopMITFactoryHittersSearchResult, ...args: any): any[] {
        let barChartData = [];
        let dataInflow = []
        for (let row of result.rows) {
            dataInflow.push(row.mit);
        }
        barChartData.push({ type: 'bar', data: dataInflow, label: "MITs" });
        return barChartData
    }
}

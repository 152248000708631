import { CommonModule } from '@angular/common';
import { Component, OnInit, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, map, startWith, Subscription } from 'rxjs';
import { LoginService, Identity } from 'src/app/service/login.service';
import { SideNavMenuComponent } from '../../side-nav-menu/side-nav-menu.component';
import { MatListModule } from '@angular/material/list';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        SideNavMenuComponent,
        MatListModule
    ],
    selector: 'app-app-dashboard',
    templateUrl: './app-dashboard.component.html',
    styleUrls: ['./app-dashboard.component.scss'],
})
export class AppDashboardComponent implements OnInit, OnDestroy {
    totalerror: number = 0;
    isAuthenticated: boolean = false;
    username: string = '';

    pageTitle: string = '';
    private loggedInSubscription: Subscription;

    constructor(
        private loginService: LoginService,
        private router: Router,
        private route: ActivatedRoute) {

        this.username = this.loginService.currentIdentity?.username || '';

        // Subscribe to login state
        this.loggedInSubscription = this.loginService.observeLoggedIn().subscribe((identity: Identity) => {
            this.username = identity.username;
        });
    }

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            startWith(this.router), // Trigger on initialization
            map(() => this.getDeepestChildRoute(this.route)),
            map(route => route.snapshot.data['pageTitle'] || 'Default Title')
        ).subscribe(pageTitle => {
            this.pageTitle = pageTitle;
        });

        this.isAuthenticated = !!this.loginService.currentIdentity;
    }

    ngOnDestroy() {
        // Unsubscribe to prevent memory leaks
        if (this.loggedInSubscription) {
            this.loggedInSubscription.unsubscribe();
        }
    }

    // Utility to get the deepest child route
    private getDeepestChildRoute(route: ActivatedRoute): ActivatedRoute {
        let childRoute = route;
        while (childRoute.firstChild) {
            childRoute = childRoute.firstChild;
        }
        return childRoute;
    }

    logout() {
        this.loginService.logout();
    }
}

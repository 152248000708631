<div *ngIf="topFactoryHittersResult.rows.length > 0"
    style="overflow-y: auto; overflow-x: hidden; height: 65vh !important;">

    <button mat-flat-button type="button" (click)="exportTopFactoryHittersToExcel()" style="margin: 1em">Export</button>
    <mat-chip>
        <span>Showing
            <strong>{{topFactoryHittersResult.rows.length}}</strong> of <strong>{{topFactoryHittersResult.count |
                number:'1.0'}}</strong>
            records</span>
    </mat-chip>

    <table mat-table [dataSource]="topFactoryHittersResult.rows" matSort matSort class="mat-elevation-z8">
        <ng-container matColumnDef="sendingUnit">
            <th mat-header-cell *matHeaderCellDef> Sending Unit </th>
            <td mat-cell *matCellDef="let element">
                {{ element.sendingUnit }}
            </td>
        </ng-container>

        <ng-container matColumnDef="errors">
            <th mat-header-cell *matHeaderCellDef> Inflow </th>
            <td mat-cell *matCellDef="let element">
                {{element.errors}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToDetails(row)"></tr>
    </table>
</div>
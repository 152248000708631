import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { TECTStateService } from 'src/app/service/tect-state.service';
import { LoginService } from 'src/app/service/login.service';
import environment from '../../../environments/static';
import { SpinnerLoaderService } from 'src/app/service/spinner-loader.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Router } from '@angular/router';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatCardModule,
        MatInputModule,
        MatButtonModule,
        MatDatepickerModule
    ],
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    deployed: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private stateService: TECTStateService,
        private dateAdapter: DateAdapter<Date>,
        private loginService: LoginService,
        private spinnerLoaderService: SpinnerLoaderService,
        private router: Router
    ) {
        this.dateAdapter.setLocale('sv-SE');
        this.deployed = environment.compiled;
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required]],
        });
    }

    public onSubmit() {
        if (this.loginForm.valid) {
            const formData = this.loginForm.getRawValue();
            this.loginService.performLogin(formData.username);

            // Navigate to another page after successful login
            // You might want to subscribe to an observable to handle success/error
            // this.loginService.observeLoggedIn((identity) => {
            //     this.spinnerLoaderService.hide();
            //     this.router.navigate(['/error-summary/']);
            // });
        }
    }
}

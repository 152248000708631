import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { selectResubmissionIsOngoing, selectPercentCompleted, selectCompletedCorrections, selectFailedCorrections } from 'src/app/state/batch-correction/batch-correction.selectors';
import { ErrorRecord } from 'src/app/model/error-record/ErrorRecord';
import { FailedResubmission } from 'src/app/model/error-record/FailedResubmission';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-batch-process-completion',
  templateUrl: './batch-process-completion.component.html',
  styleUrls: ['./batch-process-completion.component.scss']
})
export class BatchProcessCompletionComponent implements OnInit {
  isOngoing$: Observable<boolean>;
  percentCompleted$: Observable<number>;
  completedCorrections$: Observable<ErrorRecord[]>;
  failedCorrections$: Observable<FailedResubmission[]>;

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.isOngoing$ = this.store.select(selectResubmissionIsOngoing);
    this.percentCompleted$ = this.store.select(selectPercentCompleted);
    this.completedCorrections$ = this.store.select(selectCompletedCorrections);
    this.failedCorrections$ = this.store.select(selectFailedCorrections);
  }
}

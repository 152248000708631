import { createAction, props } from '@ngrx/store';
import { ErrorRecord } from 'src/app/model/error-record/ErrorRecord';
import { ErrorResubmissionRequest } from 'src/app/model/error-record/ErrorResubmissionRequest';
import { FailedResubmission } from 'src/app/model/error-record/FailedResubmission';

export enum ErrorUpdateActionTypes {
    LoadError = '[Error Update] Load Error',
    LoadErrorSuccess = '[Error Update] Load Error Success',
    UpdateError = '[Error Update] Update Error',
    UpdateErrorSuccess = '[Error Update] Update Error Success',
    UpdateErrorFailed = '[Error Update] Update Error Failed',
    ArchiveError = '[Error Update] Archive Error',
    ArchiveErrorSuccess = '[Error Update] Archive Error Success',
    UnarchiveError = '[Error Update] Unarchive Error',
    UnarchiveErrorSuccess = '[Error Update] Unarchive Error Success',
    SendEmail = '[Error Update] Send Email',
    SendEmailSuccess = '[Error Update] Send Email Success',
}

export const loadError = createAction(
    ErrorUpdateActionTypes.LoadError,
    props<{ id: number }>()
);

export const loadErrorSuccess = createAction(
    ErrorUpdateActionTypes.LoadErrorSuccess,
    props<{ errorRecord: ErrorRecord }>()
);

export const updateError = createAction(
    ErrorUpdateActionTypes.UpdateError,
    props<{ resubmissionRequest: ErrorResubmissionRequest }>()
);

export const updateErrorSuccess = createAction(
    ErrorUpdateActionTypes.UpdateErrorSuccess
);

export const updateErrorFailed = createAction(
    ErrorUpdateActionTypes.UpdateErrorFailed,
    props<{ resubmission: FailedResubmission }>()
);

export const archiveError = createAction(
    ErrorUpdateActionTypes.ArchiveError
);

export const archiveErrorSuccess = createAction(
    ErrorUpdateActionTypes.ArchiveErrorSuccess
);

export const unarchiveError = createAction(
    ErrorUpdateActionTypes.UnarchiveError
);

export const unarchiveErrorSuccess = createAction(
    ErrorUpdateActionTypes.UnarchiveErrorSuccess
);

export const sendEmail = createAction(
    ErrorUpdateActionTypes.SendEmail
);

export const sendEmailSuccess = createAction(
    ErrorUpdateActionTypes.SendEmailSuccess
);

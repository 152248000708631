import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LegacyErrorSearchRequest } from 'src/app/model/legacy-errors/LegacyErrorSearchRequest';
import { LegacyErrorSearchResultRow } from 'src/app/model/legacy-errors/LegacyErrorSearchResultRow';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ApiDataService } from 'src/app/service/api-data.service';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { NotificationService } from 'src/app/service/notification.service';
import { CommonModule, formatDate } from '@angular/common';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatTableModule,
    MatDatepickerModule,
    MatFormFieldModule,
    FormsModule,
    MatNativeDateModule,
    MatInputModule,
    MatButtonModule
  ],
  selector: 'app-legacy-error-search',
  templateUrl: './legacy-error-search.component.html',
  styleUrls: ['./legacy-error-search.component.scss']
})
export class LegacyErrorSearchComponent implements OnInit {
  request: LegacyErrorSearchRequest;
  searchResult: LegacyErrorSearchResultRow[] = [];
  dataSource = new MatTableDataSource<LegacyErrorSearchResultRow>(this.searchResult);
  dataTableVisibility = false;

  displayedColumns = [
    'serNo',
    'recTp',
    'crStamp',
    'unitSnd',
    'msgId',
    'chStamp',
    'crUser',
    'chUser'
  ];

  constructor(
    private apiDataService: ApiDataService,
    private dateAdapter: DateAdapter<Date>,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef) {

    this.dateAdapter.setLocale('sv-SE');
    this.request = new LegacyErrorSearchRequest();
  }

  ngOnInit(): void { }

  periodFromDateChange(event) {
    this.request.crStampFrom = formatDate(event.value, 'yyyy-MM-dd', 'en');
  }
  periodUntilDateChange(event) {
    this.request.crStampTo = formatDate(event.value, 'yyyy-MM-dd', 'en');
  }

  performSearch() {
    this.apiDataService.searchLegacyErrors(this.request).subscribe({
      next: (response: LegacyErrorSearchResultRow[]) => {
        this.searchResult = response;
        this.dataSource.data = this.searchResult;
        this.dataTableVisibility = true;
        this.notifyIfEmpty();
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.notificationService.showError('An unknown error occurred');
      }
    });
  }

  private notifyIfEmpty() {
    if (this.searchResult.length === 0) {
      this.notificationService.showSuccess('No errors matched your search.');
    }
  }

  onRowClicked(currentRow: LegacyErrorSearchResultRow) {
    this.router.navigate([currentRow.id], { relativeTo: this.route });
  }
}

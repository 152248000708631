<mat-dialog-content style="margin-top: 20px !important; padding:10px 70px">
    <div class="row">
        <div class="col">
            <p>
                <mat-form-field>
                    <mat-select placeholder="Event Code" [(value)]="filter.eventType"
                        (selectionChange)="eventTypeChanged($event)">
                        <mat-option [value]="null"><em>All Events</em></mat-option>
                        <mat-option *ngFor="let eventType of eventTypes" [value]="eventType">
                            {{ eventType | eventCode }}
                        </mat-option>
                    </mat-select>
                    <mat-label>Event Code</mat-label>
                </mat-form-field>
            </p>
        </div>

        <div class="col">
            <p>
                <mat-form-field>
                    <mat-select placeholder="Error Category" [(value)]="filter.errorCategory"
                        (selectionChange)="errorCategoryChanged($event)">
                        <mat-option [value]="null"><em>All Categories</em></mat-option>
                        <mat-option *ngFor="let errorCategory of errorCategories" [value]="errorCategory">
                            {{ errorCategory | errorCategory}}
                        </mat-option>
                    </mat-select>
                    <mat-label>Error Category</mat-label>
                </mat-form-field>
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <p>
                <mat-form-field>
                    <input [(ngModel)]="filter.sendingUnit" oninput="this.value = this.value.toUpperCase()" matInput
                        autocomplete="off" placeholder="Sending Unit">
                    <mat-label>Sending Unit</mat-label>
                </mat-form-field>
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col">
            <p>
                <mat-form-field>
                    <input [(ngModel)]="filter.productNumber" oninput="this.value = this.value.toUpperCase()" matInput
                        autocomplete="off" placeholder="Product Number">
                    <mat-label>Product Number</mat-label>
                </mat-form-field>
            </p>
        </div>
        <div class="col">
            <p>
                <mat-form-field>
                    <input [(ngModel)]="filter.revisionState" oninput="this.value = this.value.toUpperCase()" matInput
                        autocomplete="off" placeholder="Revision State">
                    <mat-label>Revision State</mat-label>
                </mat-form-field>
            </p>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-dialog-close mat-button>Close</button>
    <button mat-flat-button (click)="searchErrors()" class="submit-btn">Search</button>
</mat-dialog-actions>
<div class="div-panel">
    <div class="filter-row">
        <div class="filter-section date-filter">
            <mat-form-field class="small-input-width">
                <input (dateChange)="periodFromDateChange($event)" readonly="true" matInput [matDatepicker]="periodfrom"
                    placeholder="Period from" [value]="request.fromDate" (dateChange)="periodFromDateChange($event)">
                <mat-datepicker-toggle matSuffix [for]="periodfrom"></mat-datepicker-toggle>
                <mat-datepicker #periodfrom></mat-datepicker>
                <mat-label>Period from</mat-label>
            </mat-form-field>

            <mat-form-field class="small-input-width">
                <input readonly="true" matInput [matDatepicker]="perioduntil" [value]="request.toDate"
                    (dateChange)="periodUntilDateChange($event)" placeholder="Period until">
                <mat-datepicker-toggle matSuffix [for]="perioduntil"></mat-datepicker-toggle>
                <mat-datepicker #perioduntil></mat-datepicker>
                <mat-label>Period until</mat-label>
            </mat-form-field>
        </div>

        <div class="filter-section buttons">
            <button mat-flat-button (click)="performSearch()">Search</button>
        </div>
    </div>
</div>

<!---------------------------------- Error Record Table -------------------------------------------->

<div *ngIf="dataTableVisibility">
    <div style="overflow-y: auto; overflow-x: hidden; height: 65vh !important;">

        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

            <!-- Serial number Column -->
            <ng-container matColumnDef="serialNumberReferences">
                <th mat-header-cell *matHeaderCellDef> Serial Numbers
                </th>
                <td mat-cell *matCellDef="let element" (click)="onRowClicked(element)"> <a
                        class="text-link">{{element.serialNumberReferences.join(', ')}}</a>
                </td>
            </ng-container>

            <!-- Sending Unit -->
            <ng-container matColumnDef="sendingUnit">
                <th mat-header-cell *matHeaderCellDef> Sending Unit </th>
                <td mat-cell *matCellDef="let element">
                    {{element.sendingUnit}}
                </td>
            </ng-container>

            <!-- Events -->
            <ng-container matColumnDef="events">
                <th mat-header-cell *matHeaderCellDef> Events </th>
                <td mat-cell *matCellDef="let element">
                    {{element.events.join(', ')}}
                </td>
            </ng-container>

            <!-- Creation Date -->
            <ng-container matColumnDef="creationDate">
                <th mat-header-cell *matHeaderCellDef> Creation Date </th>
                <td mat-cell *matCellDef="let element">
                    {{element.creationDate}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="pageSizeOptions" [length]="errorCount" [pageIndex]="0"
            (page)="loadMoreRows($event)" showFirstLastButtons></mat-paginator>
    </div>
</div>
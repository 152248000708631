import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { ExportExcelErrorInflowRequest } from 'src/app/model/dashboard/exportExcelErrorInflowRequest';
import { AppState } from 'src/app/state';
import * as DashboardActions from '../../../state/dashboard/dashboard.actions';
import * as NavigationActions from 'src/app/state/navigation/navigation.actions';
import { ErrorInflowSearchResult } from 'src/app/state/dashboard/dashboard.reducers';
import { ErrorInflowResultRow } from 'src/app/model/dashboard/errorInflowResultRow';
import { BaseChartDirective } from 'ng2-charts';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        BaseChartDirective,
        MatTableModule,
        MatChipsModule,
        MatButtonModule,
        MatSortModule
    ],
    selector: 'app-dashboard-error-inflow',
    templateUrl: './dashboard-error-inflow.component.html',
    styleUrls: ['./dashboard-error-inflow.component.scss']
})
export class DashboardErrorInflowComponent {
    displayedColumns: string[] =
        [
            'period',
            'errors',
            'remainingErrors',
            'percentDone',
        ];

    @Input() errorInflowResult: ErrorInflowSearchResult;
    @Output() exportErrorInflow = new EventEmitter<ExportExcelErrorInflowRequest>();
    @ViewChild(MatSort) sort: MatSort;

    constructor(private store: Store<AppState>) { }

    exportErrorInflowToExcel() {
        this.exportErrorInflow.emit();
    }

    navigateToDetails(row: ErrorInflowResultRow) {
        this.store.dispatch(DashboardActions.loadDetailsErrorInflow({ row }));
        this.store.dispatch(NavigationActions.navigateToPage({ route: ['error-handling'] }));
    }
}

import { Injectable, OnDestroy } from '@angular/core';
import { LoginService } from './login.service';
import { TelemetryClient } from '../lib/telemetry-client';
import { ApiDataService } from './api-data.service';
import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LoggingService implements OnDestroy {
    private readonly telemetryClient: TelemetryClient;
    private loggedInSubscription: Subscription;

    constructor(loginService: LoginService, apiService: ApiDataService) {
        this.telemetryClient = new TelemetryClient();

        // Subscribe to login state
        this.loggedInSubscription = loginService.observeLoggedIn().subscribe(identity => {
            this.telemetryClient.setAuthenticatedUserContext(identity.username, identity.username, true);
        });

        // Load app configuration
        apiService.loadConfigFromBackend()
            .then(appConfig => {
                this.telemetryClient.initialize(appConfig);
            });
    }

    logPageView(name?: string, url?: string) { // option to call manually
        this.telemetryClient.trackPageView({
            name: name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.telemetryClient.trackEvent({ name: name }, properties);
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.telemetryClient.trackMetric({ name: name, average: average }, properties);
    }

    logException(exception: Error, severityLevel?: number) {
        this.telemetryClient.trackException({ exception: exception, severityLevel: severityLevel });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.telemetryClient.trackTrace({ message: message }, properties);
    }

    ngOnDestroy() {
        // Unsubscribe to prevent memory leaks
        if (this.loggedInSubscription) {
            this.loggedInSubscription.unsubscribe();
        }
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { LookupService } from '../service/lookup.service';
import { TopErrorsSearchResult } from '../state/dashboard/dashboard.reducers';

@Pipe({
    name: 'topErrorResultChart',
    standalone: true
})

export class TopErrorResultChartPipe implements PipeTransform {

    constructor(private lookupService: LookupService) { }

    transform(result: TopErrorsSearchResult, ...args: any): any[] {
        let barChartData = [];
        let dataInflow = []
        let dataRemaining = [];
        for (let row of result.rows) {
            dataInflow.push(row.errors);
            dataRemaining.push(row.remainingErrors);
        }

        barChartData.push({
            type: 'bar',
            data: dataInflow, label: "Inflow",
            backgroundColor: 'rgba(255, 99, 132,0.6)',
            borderColor: 'rgba(255, 99, 132,1.0)',
            hoverBackgroundColor: 'rgba(255, 99, 132, 0.8)',
            hoverBorderColor: 'rgba(255, 99, 132, 1)'
        });
        barChartData.push({
            type: 'bar',
            data: dataRemaining, label: "Remaining",
            backgroundColor: 'rgba(255, 206, 86 ,0.6)',
            borderColor: 'rgba(255, 206, 86,1.0)',
            hoverBackgroundColor: 'rgba(255, 206, 86, 0.8)',
            hoverBorderColor: 'rgba(255, 206, 86, 1)'
        });

        return barChartData
    }
}

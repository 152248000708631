import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { CorrectionStep } from 'src/app/model/batch-correction/CorrectionStep';
import { CorrectionStepType } from 'src/app/model/batch-correction/CorrectionStepType';
import { ModificationPosition } from 'src/app/model/batch-correction/ModificationPostition';

@Component({
    standalone: true,
    imports: [
        MatIconModule,
        MatCardModule,
        MatButtonModule
    ],
    selector: 'app-batch-process-step-display',
    templateUrl: './batch-process-step-display.component.html',
    styleUrls: ['./batch-process-step-display.component.scss']
})
export class BatchProcessStepDisplayComponent implements OnInit {
    @Input() step: CorrectionStep;
    @Output() removeStepEvent = new EventEmitter<CorrectionStep>();

    private readonly englishOrdinalRules = new Intl.PluralRules('en', {
        type: 'ordinal'
    });

    private readonly suffixes = {
        one: 'st',
        two: 'nd',
        few: 'rd',
        other: 'th'
    };

    constructor() { }

    ngOnInit(): void { }

    removeStep() {
        this.removeStepEvent.emit(this.step);
    }

    get stepText(): string {
        switch (this.step.stepType) {
            case CorrectionStepType[CorrectionStepType.AddCharacters]: {
                const positionName =
                    this.step.addCharactersAt === ModificationPosition[ModificationPosition.StartOfString] ?
                        'the start of' : 'the end of';
                return `Add ${this.step.charactersToAdd} to ${positionName} ${this.fieldLabel}`;
            }

            case CorrectionStepType[CorrectionStepType.RemoveCharacters]: {
                const positionName =
                    this.step.removeCharactersAt === ModificationPosition[ModificationPosition.StartOfString] ?
                        'the start of' : 'the end of';
                return `Remove ${this.step.charactersToRemove} charaters from ${positionName} ${this.fieldLabel}`;
            }

            case CorrectionStepType[CorrectionStepType.ReplaceCharacters]:
                const charList = this.step.charactersToReplace?.
                    map(s => `"${s}"`).
                    join(', ');
                return `Replace ${charList} in ${this.fieldLabel} with ${this.step.charactersToAdd}`;

            case CorrectionStepType[CorrectionStepType.CopyOtherField]:
                return `Set ${this.fieldLabel} to the same value as the field ${this.step.replacementFieldName}`;

            case CorrectionStepType[CorrectionStepType.SetToSameValue]:
                return `Set ${this.fieldLabel} to ${this.step.charactersToAdd}`;
        }

        return `${this.step.stepType} on ${this.fieldLabel}`;
    }

    private get fieldLabel(): string {
        if (this.step.payloadFieldName) {
            return `the field ${this.step.payloadFieldName}`;
        } else if (this.step.pathPart) {
            return `the ${this.getOrdinal(this.step.pathPart)} part of the endpoint`;
        } else if (this.step.headerFieldName) {
            return `the field ${this.step.headerFieldName}`;
        }
    }

    private getOrdinal(input: number): string {
        const suffix = this.suffixes[this.englishOrdinalRules.select(input)];
        return (input + suffix);
    }
}

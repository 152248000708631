import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { ErrorRecordSearchRequest } from 'src/app/model/error-search/ErrorRecordSearchRequest';
import { ApiDataService } from 'src/app/service/api-data.service';
import { LookupService } from 'src/app/service/lookup.service';
import { AppState } from '..';
import * as ErrorDetailsActions from '../error-details/error-details.actions';
import * as NotificationActions from '../notification/notification.actions';
import * as ErrorSummaryActions from './error-summary.actions';
import { selectErrorSummaryRequest } from './error-summary.selectors';

@Injectable()
export class ErrorSummaryEffects {
  private actions$ = inject(Actions);
  private store$ = inject(Store<AppState>);
  private apiDataService = inject(ApiDataService);
  private lookupService = inject(LookupService);

  loadSummary$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorSummaryActions.loadResults),
      withLatestFrom(this.store$),
      mergeMap(([, storeState]) =>
        this.apiDataService.searchErrorSummary(selectErrorSummaryRequest(storeState)).pipe(
          map((searchResult) => ErrorSummaryActions.loadResultsSuccess({ results: searchResult })),
          catchError((error) =>
            of(NotificationActions.errorNotification({ errorMessage: `Failed to load summary: ${error.message}` })))
        )
      )
    )
  );

  searchDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ErrorSummaryActions.loadDetailsSearch),
      withLatestFrom(this.store$),
      mergeMap(([action, storeState]) => {
        const request = selectErrorSummaryRequest(storeState);
        const clickedRow = action.row;

        const detailsSearchRequest = new ErrorRecordSearchRequest();
        detailsSearchRequest.errorCategory = clickedRow.errorCategory;
        detailsSearchRequest.sendingUnit = clickedRow.sendingUnit;
        detailsSearchRequest.status = this.lookupService.getErrorStatuses()[0];
        detailsSearchRequest.toDate = request.toDate;
        detailsSearchRequest.fromDate = request.fromDate;

        return [
          ErrorDetailsActions.setSearchRequest({ request: detailsSearchRequest }),
          ErrorDetailsActions.loadResults({ loadCount: false }),
        ];
      })
    )
  );
}

import { CorrectionStep } from 'src/app/model/batch-correction/CorrectionStep';
import { ErrorRecord } from 'src/app/model/error-record/ErrorRecord';
import { FailedResubmission } from 'src/app/model/error-record/FailedResubmission';
import { createReducer, on } from '@ngrx/store';
import * as BatchCorrectionActions from './batch-correction.actions';

export interface BatchCorrectionState {
    correctionSteps: CorrectionStep[] | null;
    errors: ErrorRecord[] | null;
    preview: ErrorRecord[] | null;
    resubmissionIsOngoing: boolean;
    pendingCorrections: ErrorRecord[];
    ongoingCorrections: ErrorRecord[];
    completedCorrections: ErrorRecord[];
    failedCorrections: FailedResubmission[];
}

const initialBatchCorrectionState: BatchCorrectionState = {
    correctionSteps: [],
    errors: [],
    preview: [],
    resubmissionIsOngoing: false,
    pendingCorrections: [],
    ongoingCorrections: [],
    completedCorrections: [],
    failedCorrections: [],
};

// --- REDUCER ---

export const batchCorrectionReducer = createReducer(
    initialBatchCorrectionState,
    on(BatchCorrectionActions.addStep, (state, { step }) => ({
        ...state,
        correctionSteps: [...(state.correctionSteps || []), step],
    })),

    on(BatchCorrectionActions.removeStep, (state, { step }) => ({
        ...state,
        correctionSteps: (state.correctionSteps || []).filter(c => c !== step),
    })),

    on(BatchCorrectionActions.loadErrorsForBatchCorrectionSuccess, (state, { errors }) => ({
        ...state,
        errors,
    })),

    on(BatchCorrectionActions.generatePreviewSuccess, (state, { preview }) => ({
        ...state,
        preview,
    })),

    on(BatchCorrectionActions.startBatchResubmission, (state) => ({
        ...state,
        resubmissionIsOngoing: true,
        pendingCorrections: [...(state.preview || [])],
        ongoingCorrections: [],
        completedCorrections: [],
        failedCorrections: [],
    })),

    on(BatchCorrectionActions.resubmitError, (state, { correction }) => {
        const errorId = correction.id;
        return {
            ...state,
            pendingCorrections: state.pendingCorrections.filter(c => c.id !== errorId),
            ongoingCorrections: [...(state.ongoingCorrections || []), correction],
        };
    }),

    on(BatchCorrectionActions.resubmitErrorSuccess, (state, { correction }) => {
        const errorId = correction.id;
        const ongoingCorrections = (state.ongoingCorrections || []).filter(c => c.id !== errorId);
        const completedCorrections = [...(state.completedCorrections || []), correction];
        const resubmissionIsOngoing = (ongoingCorrections.length + (state.pendingCorrections || []).length) > 0;

        return {
            ...state,
            ongoingCorrections,
            completedCorrections,
            resubmissionIsOngoing,
        };
    }),

    on(BatchCorrectionActions.resubmitErrorFailed, (state, { resubmission }) => {
        const errorId = resubmission.correction.id;
        const ongoingCorrections = (state.ongoingCorrections || []).filter(c => c.id !== errorId);
        const failedCorrections = [...(state.failedCorrections || []), resubmission];
        const resubmissionIsOngoing = (ongoingCorrections.length + (state.pendingCorrections || []).length) > 0;

        return {
            ...state,
            ongoingCorrections,
            failedCorrections,
            resubmissionIsOngoing,
        };
    }),

    on(BatchCorrectionActions.batchResubmissionCompleted, (state) => {
        // Nothing to do here. The flag "resubmissionIsOngoing" is handled in the other cases.
        return state;
    }),
);


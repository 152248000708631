import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import * as NavigationActions from './navigation.actions';

@Injectable()
export class NavigationEffects {
  private actions$ = inject(Actions);
  private router = inject(Router);

  navigateToPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NavigationActions.navigateToPage),
      tap(action => {
        this.router.navigate(action.route);
      })
    ),
    { dispatch: false }
  );
}

import { Component } from '@angular/core';
import { SpinnerLoaderService } from '../../service/spinner-loader.service';
import { CommonModule } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatProgressBarModule
  ],
  selector: 'app-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrls: ['./spinner-loader.component.scss']
})
export class SpinnerLoaderComponent {
  readonly isLoading: () => boolean;

  constructor(private loaderService: SpinnerLoaderService) {
    this.isLoading = this.loaderService.isLoading;
  }
}

import { ApplicationInsights, IEventTelemetry, IExceptionTelemetry, IMetricTelemetry, IPageViewTelemetry, ITraceTelemetry } from '@microsoft/applicationinsights-web';

export interface ITelemetryClient {
    setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie?: boolean): void;
    trackEvent(event: IEventTelemetry, customProperties?: { [key: string]: any; }): void;
    trackPageView(pageView: IPageViewTelemetry): void;
    trackException(exception: IExceptionTelemetry): void;
    trackTrace(trace: ITraceTelemetry, customProperties?: { [key: string]: any; }): void;
    trackMetric(metric: IMetricTelemetry, customProperties?: { [key: string]: any; }): void;
    startTrackPage(name?: string): void;
    stopTrackPage(name?: string, url?: string, customProperties?: Object): void;
    startTrackEvent(name: string): void;
    stopTrackEvent(name: string, properties?: Object, measurements?: Object): void;
    flush(): void;
}

type AppInsightsCall = (appInsights: ApplicationInsights) => void;

export class TelemetryClient implements ITelemetryClient {
    private readonly queue: AppInsightsCall[] = [];
    applicationInsights: ApplicationInsights | null = null;

    constructor() { }

    get isInitialized(): boolean {
        return this.applicationInsights !== null;
    }

    initialize(appConfig: any): void {
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: appConfig.applicationInsights?.instrumentationKey || "",
                enableDebug: appConfig.environment?.toLowerCase() === "development",
                enableCorsCorrelation: true,
                isCookieUseDisabled: true,
                disableFetchTracking: false,
                enableRequestHeaderTracking: true,
                enableResponseHeaderTracking: true,
                enableAutoRouteTracking: true
            }
        });

        appInsights.loadAppInsights();
        var telemetryInitializer = (envelope: any) => {
            envelope.tags["ai.cloud.role"] = "Tect.Web";
        }
        appInsights.addTelemetryInitializer(telemetryInitializer);
        this.applicationInsights = appInsights;
        this.flushQueue(this.applicationInsights);
    }

    private flushQueue(appInsights: ApplicationInsights) {
        while (this.queue.length > 0) {
            const call = this.queue[0];
            call(appInsights);
            this.queue.shift();
        }
    }

    private runOrQueue(call: (appInsights: ApplicationInsights) => void): void {
        if (this.applicationInsights !== null) {
            call(this.applicationInsights);
        } else {
            this.queue.push(call);
        }
    }

    setAuthenticatedUserContext(authenticatedUserId: string, accountId?: string, storeInCookie?: boolean): void {
        this.runOrQueue(appInsights => appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie));
    }

    trackEvent(event: IEventTelemetry, customProperties?: { [key: string]: any; }): void {
        this.runOrQueue(appInsights => appInsights.trackEvent(event, customProperties));
    }

    trackPageView(pageView: IPageViewTelemetry): void {
        this.runOrQueue(appInsights => appInsights.trackPageView(pageView));
    }

    trackException(exception: IExceptionTelemetry): void {
        this.runOrQueue(appInsights => appInsights.trackException(exception));
    }

    trackTrace(trace: ITraceTelemetry, customProperties?: { [key: string]: any; }): void {
        this.runOrQueue(appInsights => appInsights.trackTrace(trace, customProperties));
    }

    trackMetric(metric: IMetricTelemetry, customProperties?: { [key: string]: any; }): void {
        this.runOrQueue(appInsights => appInsights.trackMetric(metric, customProperties));
    }

    startTrackPage(name?: string): void {
        this.runOrQueue(appInsights => appInsights.startTrackPage(name));
    }

    stopTrackPage(name?: string, url?: string, customProperties?: { [key: string]: string }): void {
        this.runOrQueue(appInsights => appInsights.stopTrackPage(name, url, customProperties));
    }

    startTrackEvent(name: string): void {
        this.runOrQueue(appInsights => appInsights.startTrackEvent(name));
    }

    stopTrackEvent(name: string, properties?: { [key: string]: string; }, measurements?: { [key: string]: number; }): void {
        this.runOrQueue(appInsights => appInsights.stopTrackEvent(name, properties, measurements));
    }

    flush(): void {
        this.runOrQueue(appInsights => appInsights.flush());
    }
}

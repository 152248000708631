import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as NavigationActions from 'src/app/state/navigation/navigation.actions';
import * as DashboardActions from '../../../../state/dashboard/dashboard.actions';
import { ExportExcelTopFactoryHittersRequest } from 'src/app/model/dashboard/exportExcelTopFactoryHittersRequest';
import { TopFactoryHittersSearchResult } from 'src/app/state/dashboard/dashboard.reducers';
import { TopFactoryHittersResultRow } from 'src/app/model/dashboard/topFactoryHittersResultRow';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    BaseChartDirective,
    MatTableModule,
    MatChipsModule,
    MatButtonModule,
    MatSortModule
  ],
  selector: 'app-dashboard-top-factory-hitters',
  templateUrl: './dashboard-top-factory-hitters.component.html',
  styleUrls: ['./dashboard-top-factory-hitters.component.scss']
})
export class DashboardTopFactoryHittersComponent implements OnInit {
  displayedColumns: string[] =
    [
      'sendingUnit',
      'errors',
    ];

  @Input() topFactoryHittersResult: TopFactoryHittersSearchResult;
  @Output() exportTopFactoryHitters = new EventEmitter<ExportExcelTopFactoryHittersRequest>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit(): void { }

  navigateToDetails(row: TopFactoryHittersResultRow) {
    this.store.dispatch(DashboardActions.loadDetailsSearchTopFactoryHitters({ row }));
    this.store.dispatch(NavigationActions.navigateToPage({ route: ['error-handling'] }));
  }

  exportTopFactoryHittersToExcel() {
    this.exportTopFactoryHitters.emit();
  }
}

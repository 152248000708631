import { Component, OnInit, ViewChild } from '@angular/core';
import MostCommonErrorReportRequest from 'src/app/model/statistics/MostCommonErrorReportRequest';
import { NotificationService } from 'src/app/service/notification.service';
import MostCommonErrorReportResultRow from 'src/app/model/statistics/MostCommonErrorReportResultRow';
import { ApiDataService } from 'src/app/service/api-data.service';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { MostCommonErrorFilterComponent } from '../most-common-error-filter/most-common-error-filter.component';
import { SpinnerLoaderComponent } from '../../spinner-loader/spinner-loader.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { ErrorCategoryPipe } from 'src/app/pipe/error-category.pipe';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MostCommonErrorFilterComponent,
    SpinnerLoaderComponent,
    MatDatepickerModule,
    MatFormFieldModule,
    RouterModule,
    ErrorCategoryPipe,
    MatTableModule,
    MatSortModule
  ],
  selector: 'app-most-common-error',
  templateUrl: './most-common-error.component.html',
  styleUrls: ['./most-common-error.component.scss']
})
export class MostCommonErrorComponent implements OnInit {
  mostCommonErrorReport: MostCommonErrorReportResultRow[] = [];
  dataSource = new MatTableDataSource<MostCommonErrorReportResultRow>(this.mostCommonErrorReport);
  showingGroupedReport = false;

  statisticTableHeader: string[] = [
    'sendingUnit',
    'errorCategory',
    'numberOfErrors',
    'remainingErrors',
    'totalErrors',
  ];

  @ViewChild(MatSort) set content(sort: MatSort) { this.dataSource.sort = sort; }

  constructor(
    private apiDataService: ApiDataService,
    private notificationService: NotificationService) {
  }

  ngOnInit() { }

  private notifyIfEmpty() {
    if (this.mostCommonErrorReport.length === 0) {
      this.notificationService.showSuccess('No errors matched your search.');
    }
  }

  performSearch(event: MostCommonErrorReportRequest) {
    this.apiDataService.getMostCommonErrorReport(event).subscribe((response: MostCommonErrorReportResultRow[]) => {
      this.mostCommonErrorReport = response;
      this.dataSource.data = this.mostCommonErrorReport;
      this.notifyIfEmpty();
    }, (error) => {
      this.notificationService.showError('An unknown error occurred');
    });
  }

  downloadExcel(event: MostCommonErrorReportRequest) {
    this.apiDataService.downloadMostCommonErrorReport(event);
  }
}

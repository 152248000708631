import { createAction, props, union } from "@ngrx/store";
import { DashboardSearchRequest } from "src/app/model/dashboard/dashboardSearchRequest";
import { MITInflowResultRow } from "src/app/model/missing-in-tracy/MITInflowResultRow";
import { TopMITFactoryHittersResultRow } from "src/app/model/missing-in-tracy/topMITFactoryHittersResultRow";

export enum MITActionTypes {
    loadMITInflowResults = '[MIT] Load MIT Inflow Results',
    loadMITInflowResultsSuccess = '[MIT] Load MIT Inflow Results Success',
    setSearchRequests = '[MIT] Set Search Requests',
    loadTopMITFactoryHittersResults = '[MIT] Load Top MIT Factory Hitters Results',
    loadTopMITFactoryHittersResultsSuccess = '[MIT] Load Top MIT Factory Hitters Results Success',
}

export const setSearchRequests = createAction(
    MITActionTypes.setSearchRequests,
    props<{ request: DashboardSearchRequest }>()
);

export const loadMITInflowResults = createAction(
    MITActionTypes.loadMITInflowResults,
    props<{ loadCount: boolean }>()
);

export const loadMITInflowResultsSuccess = createAction(
    MITActionTypes.loadMITInflowResultsSuccess,
    props<{
        rows: MITInflowResultRow[],
        count: number | null
    }>()
);

export const loadTopMITFactoryHittersResults = createAction(
    MITActionTypes.loadTopMITFactoryHittersResults,
    props<{ loadCount: boolean }>()
);

export const loadTopMITFactoryHittersResultsSuccess = createAction(
    MITActionTypes.loadTopMITFactoryHittersResultsSuccess,
    props<{
        rows: TopMITFactoryHittersResultRow[],
        count: number | null
    }>()
);
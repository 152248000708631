import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { TopMITFactoryHittersSearchResult } from 'src/app/state/missing-in-tracy/missing-in-tracy.reducers';
import { BaseChartDirective } from 'ng2-charts';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';

@Component({
    standalone: true,
    imports: [
        BaseChartDirective,
        CommonModule,
        MatTableModule,
        MatChipsModule,
        MatSortModule
    ],
    selector: 'app-MIT-top-factory-hitters',
    templateUrl: './missing-in-tracy-top-factory-hitters.component.html',
    styleUrls: ['./missing-in-tracy-top-factory-hitters.component.scss']
})
export class TopMITFactoryHittersComponent implements OnInit {
    displayedColumns: string[] =
        [
            'sendingUnit',
            'mits',
        ];

    @Input() topMITFactoryHittersResult: TopMITFactoryHittersSearchResult;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    constructor(
        private store: Store<AppState>
    ) { }

    ngOnInit(): void { }
}

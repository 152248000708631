<div *ngIf="searchRequest$ | async as request">
    <app-dashboard-search [initialSearchRequest]="request" (performSearch)="performSearch($event)" />
</div>

<mat-tab-group>
    <mat-tab label="Error Inflow">
        <div *ngIf="errorInflowResult$ | async as errorInflowResult">
            <div *ngIf="errorInflowResult.rows.length != 0">
                <app-error-inflow-bar-chart [barChartData]="errorInflowResult | errorInflowResultChart"
                    [barChartLabels]="errorInflowResult| errorInflowResultLabels">
                </app-error-inflow-bar-chart>
            </div>
            <app-dashboard-error-inflow [errorInflowResult]="errorInflowResult"
                (exportErrorInflow)="exportErrorInflowToExcel()"></app-dashboard-error-inflow>
            <div class="empty-result-message" *ngIf="errorInflowResult.rows.length == 0">
                <div style="margin-left: 40% !important;">
                    <i class="fa fa-search " style="font-size: 16.2rem; color: #d0dde7; font-weight: 900;"></i>
                    <p style="margin-top: 5%; width: 180px; font-size: 20px; font-weight: 100;">
                        There are no error records which match your search.
                    </p>
                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="Top Errors">
        <div *ngIf="topErrorsResult$ | async as topErrorsResult">
            <div *ngIf="topErrorsResult.rows.length != 0">
                <app-top-errors-bar-chart [barChartData]="topErrorsResult|topErrorResultChart"
                    [barChartLabels]="topErrorsResult|topErrorResultLabelsChart">
                </app-top-errors-bar-chart>
            </div>
            <app-dashboard-top-errors [topErrorsResult]="topErrorsResult" (exportTopErrors)="exportTopErrorsToExcel()">
            </app-dashboard-top-errors>
            <div class="empty-result-message" *ngIf="topErrorsResult.rows.length == 0">
                <div style="margin-left: 40% !important;">
                    <i class="fa fa-search " style="font-size: 16.2rem; color: #d0dde7; font-weight: 900;"></i>
                    <p style="margin-top: 5%; width: 180px; font-size: 20px; font-weight: 100;">
                        There are no error records which match your search.
                    </p>
                </div>
            </div>
        </div>
    </mat-tab>

    <mat-tab label="Top Factory Hitters">
        <div *ngIf="topFactoryHittersResult$ | async as topFactoryHittersResult">
            <div *ngIf="topFactoryHittersResult.rows.length != 0">
                <app-top-factory-hitters-bar-chart
                    [barChartData]="topFactoryHittersResult| topFactoryHittersResultChart"
                    [barChartLabels]="topFactoryHittersResult| topFactoryHittersResultLabelsChart">
                </app-top-factory-hitters-bar-chart>
            </div>
            <app-dashboard-top-factory-hitters [topFactoryHittersResult]="topFactoryHittersResult"
                (exportTopFactoryHitters)="exportTopFactoryHittersToExcel()">
            </app-dashboard-top-factory-hitters>
            <div class="empty-result-message" *ngIf="topFactoryHittersResult.rows.length == 0">
                <div style="margin-left: 40% !important;">
                    <i class="fa fa-search " style="font-size: 16.2rem; color: #d0dde7; font-weight: 900;"></i>
                    <p style="margin-top: 5%; width: 180px; font-size: 20px; font-weight: 100;">
                        There are no error records which match your search.
                    </p>
                </div>
            </div>
        </div>
    </mat-tab>
</mat-tab-group>
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import * as DashboardActions from '../../../state/dashboard/dashboard.actions';
import { ExportExcelTopErrorsRequest } from 'src/app/model/dashboard/exportExcelTopErrorsRequest';
import { TopErrorInflowResultRow } from 'src/app/model/dashboard/topErrorInflowResultRow';
import * as NavigationActions from 'src/app/state/navigation/navigation.actions';
import { TopErrorsSearchResult } from 'src/app/state/dashboard/dashboard.reducers';
import { CommonModule } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { MatTableModule } from '@angular/material/table';
import { ErrorCategoryPipe } from 'src/app/pipe/error-category.pipe';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonModule } from '@angular/material/button';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    BaseChartDirective,
    MatTableModule,
    ErrorCategoryPipe,
    MatChipsModule,
    MatButtonModule,
    MatSortModule
  ],
  selector: 'app-dashboard-top-errors',
  templateUrl: './dashboard-top-errors.component.html',
  styleUrls: ['./dashboard-top-errors.component.scss']
})
export class DashboardTopErrorsComponent implements OnInit {
  displayedColumns: string[] =
    [
      'errorCategory',
      'errors',
      'remainingErrors',
    ];

  @Input() topErrorsResult: TopErrorsSearchResult;
  @Output() exportTopErrors = new EventEmitter<ExportExcelTopErrorsRequest>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private store: Store<AppState>) {
  }

  ngOnInit(): void { }

  navigateToDetails(row: TopErrorInflowResultRow) {
    this.store.dispatch(DashboardActions.loadDetailsTopErrors({ row }));
    this.store.dispatch(NavigationActions.navigateToPage({ route: ['error-handling'] }));
  }

  exportTopErrorsToExcel() {
    this.exportTopErrors.emit();
  }
}

<div *ngIf="topErrorsResult.rows.length > 0" style="overflow-y: auto; overflow-x: hidden; height: 65vh !important;">

    <button mat-flat-button type="button" (click)="exportTopErrorsToExcel()" style="margin: 1em">Export</button>
    <mat-chip>
        <span>Showing
            <strong>{{topErrorsResult.rows.length}}</strong> of <strong>{{topErrorsResult.count |
                number:'1.0'}}</strong>
            records</span>
    </mat-chip>

    <table mat-table [dataSource]="topErrorsResult.rows" matSort matSort class="mat-elevation-z8">

        <ng-container matColumnDef="errorCategory">
            <th mat-header-cell *matHeaderCellDef> Category </th>
            <td mat-cell *matCellDef="let element">
                {{ element.errorCategory | errorCategory }}
            </td>
        </ng-container>

        <ng-container matColumnDef="errors">
            <th mat-header-cell *matHeaderCellDef> Inflow </th>
            <td mat-cell *matCellDef="let element">
                {{element.errors}}
            </td>
        </ng-container>

        <ng-container matColumnDef="remainingErrors">
            <th mat-header-cell *matHeaderCellDef> Remaining </th>
            <td mat-cell *matCellDef="let element">
                {{element.remainingErrors}}
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToDetails(row)"></tr>
    </table>
</div>
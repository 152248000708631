import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ChartOptions, CategoryScale, LinearScale, Chart } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    standalone: true,
    imports: [
        BaseChartDirective
    ],
    selector: 'app-MIT-top-factory-hitters-bar-chart',
    templateUrl: './missing-in-tracy-top-factory-hitters-bar-chart.component.html',
    styleUrls: ['./missing-in-tracy-top-factory-hitters-bar-chart.component.scss']
})
export class TopMITFactoryHittersBarChartComponent implements OnInit {
    constructor() {
        Chart.register(CategoryScale, LinearScale);
    }

    public barChartOptions: ChartOptions = {
        scales: {
            y: {
                stacked: false,
                beginAtZero: true,
                type: 'linear'
            }
        },
        responsive: true,
        maintainAspectRatio: true
    };

    @Input() barChartData: any[];
    @Input() barChartLabels: string[];

    public barChartLegend = false;

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges) { }
}

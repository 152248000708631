import { CommonModule, formatDate } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MITFilterSelection } from 'src/app/model/missing-in-tracy/MITFilterSelection';
import { MITSearchRequest } from 'src/app/model/missing-in-tracy/MITSearchRequest';
import { LookupService } from 'src/app/service/lookup.service';
import { ErrorFilter } from '../../error-details/error-details.component';
import { MITFilterComponent } from '../missing-in-tracy-filter/missing-in-tracy-filter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@Component({
    standalone: true,
    imports: [
        MatDatepickerModule,
        CommonModule,
        MatChipsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatTooltipModule,
        MatButtonModule
    ],
    selector: 'app-mit-search',
    templateUrl: './missing-in-tracy-search.component.html',
    styleUrls: ['./missing-in-tracy-search.component.scss']
})
export class MITSearchComponent implements OnInit {
    @Input() set initialSearchRequest(value: MITSearchRequest) {
        this.searchRequest = {
            ...value
        } as MITSearchRequest;

        this.refreshErrorFilter();
    }

    @Output() performSearch = new EventEmitter<MITSearchRequest>();

    public currentDate: string;
    public dateGroupingOptions = [];
    public searchRequest: MITSearchRequest;
    public filterArray: ErrorFilter[] = [];

    constructor(private lookupService: LookupService,
        private dialog: MatDialog,) {
        this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    }

    ngOnInit(): void {
        this.dateGroupingOptions = this.lookupService.getDateGroupingOptions();
    }

    toDateChange(event) {
        this.searchRequest.toDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
    }

    fromDateChange(event) {
        this.searchRequest.fromDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
    }

    dateGroupingOptionChanged(event) {
        this.searchRequest.dateGrouping = event.value;
    }

    searchErrors() {
        this.performSearch.emit(this.searchRequest);
    }

    showFilterDialog() {
        const currentSearchRequest = this.searchRequest;
        const dialogRef = this.dialog.open(MITFilterComponent, {
            minHeight: '70%',
            maxHeight: '80%',
            maxWidth: '60%',
            minWidth: '20%',
            data: currentSearchRequest
        });

        dialogRef.afterClosed().subscribe((response: MITFilterSelection) => {
            if (response) {
                this.searchRequest = {
                    ...this.searchRequest,
                    ...response
                };

                this.refreshErrorFilter();
                this.searchErrors();
            }
        });
    }

    public refreshErrorFilter() {
        this.filterArray = [];

        if (this.searchRequest.eventType) {
            this.filterArray.push({
                name: 'eventType', value: `Event code: ${this.searchRequest.eventType}`
            });
        }

        if (this.searchRequest.sendingUnit) {
            this.filterArray.push({
                name: 'sendingUnit', value: `Sending unit: ${this.searchRequest.sendingUnit}`
            });
        }
    }

    removeFromErrorFilter(filterParam: ErrorFilter) {
        const index = this.filterArray.findIndex(x => x.name === filterParam.name);

        if (index >= 0) {
            this.filterArray.splice(index, 1);
            this.searchRequest[filterParam.name] = null;
        }
    }

    removeFromSearch(searchParam) {
        this.removeFromErrorFilter(searchParam);
        this.searchErrors();
    }
}

import { Component, OnInit } from '@angular/core';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoginComponent } from '../../login/login.component';

@Component({
    standalone: true,
    selector: 'app-app-layout',
    imports: [
        CommonModule,
        RouterModule,
        LoginComponent,
    ],
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.scss'],
    animations: [
        // the fade-in/fade-out animation.
        trigger('simpleFadeAnimation', [

            // the "in" style determines the "resting" state of the element when it is visible.
            state('in', style({ opacity: 3 })),

            // fade in when created. this could also be written as transition('void => *')
            transition(':enter', [
                style({ opacity: 0 }),
                animate(1000)
            ]),

            // fade out when destroyed. this could also be written as transition('void => *')
            transition(':leave',
                animate(1000, style({ opacity: 0 })))
        ])
    ]
})
export class AppLayoutComponent implements OnInit {
    constructor() { }

    ngOnInit() { }
}

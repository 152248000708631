import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ChartOptions, CategoryScale, LinearScale, Chart } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
    standalone: true,
    imports: [BaseChartDirective],
    selector: 'app-MIT-inflow-bar-chart',
    templateUrl: './missing-in-tracy-inflow-bar-chart.component.html',
    styleUrls: ['./missing-in-tracy-inflow-bar-chart.component.scss']
})
export class MITInflowBarChartComponent implements OnInit {
    constructor() {
        Chart.register(CategoryScale, LinearScale);
    }

    public barChartOptions: ChartOptions = {
        scales: {
            x: {
                type: 'category',
            },
            y: {
                stacked: false,
                beginAtZero: true
            },
        },
        plugins: {
            tooltip: {
                enabled: true,
                callbacks: {
                    label: ({ chart, dataIndex }) => {
                        const inflow = chart.data.datasets[0].data[dataIndex];
                        const remaining = chart.data.datasets[1].data[dataIndex];
                        const percentHealed = chart.data.datasets[2].data[dataIndex];

                        return `Inflow: ${inflow} Remaining: ${remaining} Percent Healed: ${percentHealed}`;
                    }
                }
            },
            legend: {
                labels: {
                    filter: function (item) {
                        // Logic to remove a particular legend item goes here
                        return !item.text.includes('Percent Healed');
                    }
                }
            },
        },
        responsive: true,
        maintainAspectRatio: true
    };

    @Input() barChartData: any[];
    @Input() barChartLabels: string[];
    @Input() barChartRemaining: any[];

    public barChartLegend = false;

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges) { }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { CommonModule, formatDate } from '@angular/common';
import { LookupService } from 'src/app/service/lookup.service';
import { ErrorRecordSearchRequest } from 'src/app/model/error-search/ErrorRecordSearchRequest';
import { LoginService } from 'src/app/service/login.service';
import { Policies } from 'src/constants';
import { MatSelectModule } from '@angular/material/select';
import { ErrorCategorySelectComponent } from './error-category-select/error-category-select.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ErrorStatusPipe } from 'src/app/pipe/error-status.pipe';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { EventCodePipe } from 'src/app/pipe/event-code.pipe';
import { MatNativeDateModule } from '@angular/material/core';

export interface ErrorFilterSelection {
    unitReference: string;
    sendingUnit: string;
    fromDate: string;
    toDate: string;
    status: string;
    errorCode: string;
    errorCategory: string;
    eventCode: string;
}

@Component({
    standalone: true,
    imports: [
        CommonModule,
        MatSelectModule,
        ErrorCategorySelectComponent,
        MatDatepickerModule,
        MatDialogModule,
        ErrorStatusPipe,
        FormsModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        EventCodePipe,
        MatNativeDateModule
    ],
    selector: 'app-error-filter',
    templateUrl: './error-filter.component.html',
    styleUrls: ['./error-filter.component.scss']
})
export class ErrorFilterComponent implements OnInit {
    filter: ErrorFilterSelection;
    public statuses: string[] = [];
    public eventCodes = [];
    public errorCategories: string[] = [];
    public currentDate: string;
    public readonly canReadAllErrors: boolean;

    constructor(
        private loginService: LoginService,
        public dialogRef: MatDialogRef<ErrorFilterComponent>,
        private lookupService: LookupService,
        @Inject(MAT_DIALOG_DATA) public data: ErrorRecordSearchRequest) {

        this.filter = data;
        this.currentDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
        this.canReadAllErrors = this.loginService.acceptedByPolicy(Policies.CanReadAllErrors);
    }

    ngOnInit(): void {
        this.errorCategories = this.lookupService.getErrorCategories();
        this.statuses = this.lookupService.getErrorStatuses();
        this.eventCodes = this.lookupService.getEventCodes();
    }

    doErrorSearch() {
        this.dialogRef.close(this.filter);
    }

    periodFromDateChange(event) {
        this.filter.fromDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
    }

    periodToDateChange(event) {
        this.filter.toDate = formatDate(event.value, 'yyyy-MM-dd', 'en');
    }

    statusChanged(event) {
        this.filter.status = event.value;
    }

    categoryChanged(event: string) {
        this.filter.errorCategory = event;
    }

    eventCodeChanged(event) {
        this.filter.eventCode = event.value;
    }
}


import { createAction, props } from '@ngrx/store';

export enum NavigationActionTypes {
  NavigateToPage = '[Navigation] Navigate To Page',
}

export const navigateToPage = createAction(
  NavigationActionTypes.NavigateToPage,
  props<{ route: string[] }>()
);
